var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mr-2",class:{
          'editable-details-title':
            _vm.issueDetailsTitle === 'Additional details',
          'card-title--black3': _vm.issueDetailsTitle === 'Details',
        }},[_vm._v(" "+_vm._s(_vm.issueDetailsTitle)+" ")]),(_vm.userHasInternalWriteAccess && !_vm.editIssueDetails)?_c('div',{staticClass:"mb-2 pointer"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.editTurbineIssueDetails.apply(null, arguments)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"size":"19.5px","color":"black3 pointer"}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,false,3154722646)},[_c('span',[_vm._v("Edit details")])])],1)]):_vm._e()]),(_vm.editDetailsLoading)?_c('div',{staticClass:"d-flex align-center justify-center mb-2"},[_c('v-progress-circular',{attrs:{"size":36,"color":"primary","indeterminate":""}})],1):(_vm.editIssueDetails)?_c('EditIssueDetailsWidget',{attrs:{"issueDetails":_vm.issueDataDetails},on:{"sendIssueDetailsEdit":_vm.sendIssueDetailsEdit,"cancelEdit":_vm.cancelEditIssueDetails}}):_c('div',{staticClass:"detail-markdown"},[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(_vm.issueDataDetails))])],1),(_vm.supersededIssues.length > 0 && !_vm.editIssueDetails)?_c('div',{staticClass:"superseded-issues"},[_c('p',{staticClass:"superseded-issues-title"},[_vm._v(" This issue supersedes the following issues: ")]),_c('v-list',{staticClass:"pt-0"},_vm._l((_vm.supersededIssues),function(issue){return _c('v-list-item',{key:issue.id},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-center superseded-issues-container"},[_c('span',[_c('v-icon',{attrs:{"size":"2rem"}},[_vm._v("mdi-circle-small")])],1),_c('router-link',{attrs:{"to":{
                  name: 'IssueDetail',
                  params: {
                    orgId: issue.org_id,
                    siteId: issue.farm_id,
                    turbineId: issue.turbine_id,
                    issueId: issue.id,
                  },
                }}},[_vm._v(" "+_vm._s(issue.definition.name)+" ")])],1)])],1)}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }