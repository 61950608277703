<template>
  <div class="recommendations-container">
    <div
      @click="toggleUploadDialog(false)"
      class="backdrop"
      v-if="showDialog"
    ></div>
    <div
      @click="toggleCommentDialog(false)"
      class="backdrop"
      v-if="isCreateComment && !showDialog"
    ></div>
    <div
      @click="toggleFixedDialog(false)"
      class="backdrop"
      v-if="showSetStatusFixedWidget && !isFixedWidgetUploadOpen"
    ></div>
    <div
      class="d-flex justify-center align-center recommendations-container__spinner"
      v-if="loadingTimeline"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <table class="static-length">
      <thead>
        <tr>
          <th>
            <div class="d-flex justify-space-between">
              <div
                class="d-flex align-center comment-btn-container recommendation-label"
              >
                <label class="ma-0 mr-4">Next steps</label>
                <button class="comment-btn" @click="openCreateCommentDialog">
                  COMMENT
                </button>
                <NewCreateCommentDialog
                  v-if="isCreateComment"
                  :attachments="attachments"
                  @postComment="postComment"
                  @openUploadDialog="toggleUploadDialog"
                  @removeAttachment="removeAttachment"
                  @emptyAttachments="emptyAttachments"
                  @closeCreateCommentDialog="isCreateComment = false"
                />
                <UploadDialog
                  v-if="showDialog"
                  @setShowDialog="toggleUploadDialog"
                  :isNotSiteDocument="true"
                  title="Upload attachment"
                  @setFile="setFile"
                  :isCreateComment="true"
                />
              </div>
              <div class="d-flex align-center recommendation-label">
                <label class="ma-0 mr-2 ml-2">Ticket Submitted</label>
                <div class="ticket-submitted-badge ml-2 mr-4">
                  <v-text-field
                    ref="ticketInput"
                    hide-details="auto"
                    v-model="ticketSubmitted"
                    class="ticket-input"
                    placeholder="N/A"
                    :disabled="
                      latestTicketName && !isEditTicketName ? true : false
                    "
                    dense
                    clearable
                    @blur="submitTicketName($event, 'ticketSubmit')"
                    @keypress.enter="blurTicketInput"
                  ></v-text-field>
                </div>
                <div class="edit-ticket">
                  <v-icon
                    v-if="latestTicketName"
                    @click="callEditTicketName()"
                    class="icon-hover"
                    size="24"
                  >
                    mdi-pencil
                  </v-icon>
                </div>
              </div>
            </div>
          </th>
          <th class="recommendation-label">
            <label class="ma-0 ml-2">LAST UPDATED</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="
            currentPage === 1 &&
            latestStatus.definition_id !== 8 &&
            !loadingTimeline
          "
        >
          <td>
            <div class="d-flex justify-space-between">
              <div class="suggested-recommendations">
                <NewSuggestedRecommendations
                  :open="
                    latestStatus.definition_id === 1 ||
                    latestStatus.definition_id === 2 ||
                    latestStatus.definition_id === 3
                      ? 0
                      : 1
                  "
                />
              </div>
              <div class="mr-2 align-self-end" v-if="showMarkCompleteButton">
                <button
                  class="mark-complete-btn"
                  :disabled="isNewRecommendation"
                  @click="markIssueFixed"
                >
                  MARK COMPLETE
                </button>
                <div
                  v-if="showSetStatusFixedWidget"
                  class="fixed-widget-container"
                >
                  <NewFixedStatusWidget
                    :userHasWriteAccess="userHasWriteAccess"
                    :userHasInternalAccess="userHasInternalAccess"
                    :showDialog="isFixedWidgetUploadOpen"
                    @isNewRecommendation="isNewRecommendation = true"
                    @closeFixedStatusDialog="showSetStatusFixedWidget = false"
                    @openUploadDialog="showFixedWidgetUpload"
                    @updateAtAGlance="updateAtAGlance"
                  />
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="ml-2 last-updated">{{ latestStatus.created_ts }}</div>
          </td>
        </tr>
        <tr v-for="item in paginatedComments" :key="item.id">
          <td>
            <CommentsTimelineItem :item="item" />
          </td>
          <td>
            <div class="ml-2 last-updated">{{ commentDate(item) }}</div>
          </td>
        </tr>
      </tbody>
      <tfoot class="table-footer">
        <tr>
          <td colspan="2">
            <div class="pagination-expression">
              <div
                class="d-flex align-center mr-6"
                @click="goToPreviousPage"
                :class="{ 'previous-disabled': currentPage === 1 }"
              >
                <v-icon :disabled="currentPage === 1">mdi-chevron-left</v-icon>
                <div>PREVIOUS</div>
              </div>

              <div
                v-for="page in paginationRange"
                :key="page"
                class="page-number"
              >
                <span
                  class="mr-1"
                  :class="{ 'active-page': page === currentPage }"
                  @click="goToPage(page)"
                  >{{ page }}</span
                >
              </div>

              <div class="d-flex align-center ml-6" @click="goToNextPage">
                <div :class="{ 'next-disabled': isLastPage }">NEXT</div>
                <v-icon :disabled="isLastPage">mdi-chevron-right</v-icon>
              </div>
            </div>
            <div
              class="d-flex justify-center align-center page-range ml-6 mt-1"
              v-if="currentPage <= totalPages && totalPages > 5"
            >
              <div class="mr-2">
                <input
                  type="number"
                  id="page-input"
                  min="1"
                  :value="currentPage"
                  @blur="validatePageInput"
                  @keypress.enter="validatePageInput"
                />
              </div>
              <p class="ma-0 mr-1">of</p>
              <div class="ml-1">
                {{ totalPages }}
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import NewSuggestedRecommendations from "@/components/NewIssueDetailComponents/NewSuggestedRecommendations";
import CommentsTimelineItem from "@/components/NewIssueDetailComponents/CommentsTimelineItem";
import NewCreateCommentDialog from "./NewCreateCommentDialog";
import UploadDialog from "@/components/UploadDialog";
import NewFixedStatusWidget from "@/components/NewIssueDetailComponents/NewFixedStatusWidget";
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import {
  getFirstNames,
  stringToColor,
  deepCopyFunction,
} from "@/helpers/functions";
/**
 * Displays a timeline of issue comments/statuses and an editor to post a comment
 */
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "RecommendationsTab",
  components: {
    NewSuggestedRecommendations,
    CommentsTimelineItem,
    NewCreateCommentDialog,
    UploadDialog,
    NewFixedStatusWidget,
  },
  props: {
    latestStatus: {
      type: Object,
      required: false,
      default: () => {},
    },
    issueId: {
      type: [String, Number],
      default: null,
      required: true,
    },
    userHasInternalWriteAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    userHasInternalAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    userHasWriteAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    recommendationSelection: {
      type: String,
      required: false,
      default: "",
    },
    loadingTimeline: {
      type: Boolean,
      required: false,
      default: false,
    },
    commentsTimelineItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    lossesGainsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      lastValidPage: 1,
      rowsPerPage: 5,
      stringToColor,
      routeHash: "",
      isCreateComment: false,
      showDialog: false,
      isFixedWidgetUploadOpen: false,
      attachments: [],
      showSetStatusFixedWidget: false,
      isNewRecommendation: false,
      ticketSubmitted: "",
      latestTicketName: null,
      loadingSaveTicketName: false,
      loadingEditTicketNames: false,
      workOrders: [],
      loadingFetchIssueWorkOrders: false,
      isEditTicketName: false,
    };
  },
  computed: {
    ...mapState({
      commentData: (state) => state.comments.comments,
      statusHistoryData: (state) => state.issueDetail.statusHistory,
      userData: (state) => state.user.userData,
      loading: (state) => state.issueDetail.loading,
      spreadsheetLoading: (state) => state.documents.spreadsheetLoading,
    }),
    totalPages() {
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
    totalItems() {
      return this.commentsList.length;
    },
    paginatedComments() {
      let startIndex = (this.currentPage - 1) * this.rowsPerPage;
      let endIndex = startIndex + this.rowsPerPage;
      if (!this.loadingTimeline && this.commentsList.length > 0) {
        return this.commentsList.slice(startIndex, endIndex);
      }
      return [];
    },
    paginationRange() {
      let start = Math.max(this.currentPage - 2, 1);
      let end = Math.min(start + 4, this.totalPages);

      if (this.totalPages - this.currentPage < 2) {
        start = Math.max(this.totalPages - 4, 1);
        end = this.totalPages;
      }

      let range = [];
      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      return range;
    },
    isLastPage() {
      if (!this.rowsPerPage || this.commentsList.length == 0) {
        return false;
      } else if (
        Math.ceil(this.commentsList.length / this.rowsPerPage) ===
        this.currentPage
      ) {
        return true;
      }
      return false;
    },
    commentsList() {
      if (this.commentsTimelineItems.length > 0) {
        return this.setCommentsMap(this.commentsTimelineItems);
      }
      return [];
    },
    showMarkCompleteButton() {
      if (
        this.userHasWriteAccess &&
        this.latestStatus.definition_name_external !== "Fixed" &&
        !this.latestStatus?.definition_name_external?.includes("Unconfirmed")
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getComments: "comments/getComments",
      updateIsNotificationNew: "notifications/updateIsNotificationNew",
      handleAxiosError: "error/raiseError",
    }),
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber && pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
    validatePageInput(event) {
      const pageValue = parseInt(event.target.value);
      if (pageValue && Number.isInteger(pageValue)) {
        this.goToPage(pageValue);
      }
    },
    setCommentsMap(commentsList) {
      return commentsList.map((comment) => ({
        id: comment.id,
        attachments: comment.attachments,
        name: comment.user_name_full
          ? comment.user_name_full
          : comment.user_full_name,
        avatar: getFirstNames(
          comment.user_name_full
            ? comment.user_name_full
            : comment.user_full_name,
        ),
        date: `${dayjs(
          comment.comment_ts ? comment.comment_ts : comment.created_ts,
        )
          .utc()
          .format("DD MMM YYYY, HH:mm")} UTC`,
        comment: comment.comment ? comment.comment : "",
        statusComment: comment.comment_external ? comment.comment_external : "",
        isStaff: false,
        isTag: comment.created_ts ? true : false,
        status: comment.definition_name_external
          ? comment.definition_name_external
          : "",
      }));
    },
    commentDate(item) {
      return dayjs(item.date).format("YYYY-MM-DD HH:mm:ss") + " (UTC)";
    },
    openCreateCommentDialog() {
      this.isCreateComment = !this.isCreateComment;
    },
    toggleUploadDialog(showDialog) {
      this.showDialog = showDialog;
    },
    toggleCommentDialog(showModal) {
      this.isCreateComment = showModal;
    },
    toggleFixedDialog(showFixedDialog) {
      this.showSetStatusFixedWidget = showFixedDialog;
    },
    setFile(chosenFile) {
      this.attachments.push(...chosenFile);
    },
    removeAttachment(attachment) {
      this.attachments = this.attachments.filter((item) => item !== attachment);
    },
    emptyAttachments() {
      this.attachments = [];
    },
    postComment() {
      this.$emit("postComment");
      this.isCreateComment = false;
    },
    markIssueFixed() {
      this.showSetStatusFixedWidget = !this.showSetStatusFixedWidget;
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        if (this.showDialog) {
          this.showDialog = false;
        } else if (this.isCreateComment && !this.showDialog) {
          this.isCreateComment = false;
        } else if (this.isFixedWidgetUploadOpen) {
          this.isFixedWidgetUploadOpen = false;
        } else if (
          this.showSetStatusFixedWidget &&
          !this.isFixedWidgetUploadOpen
        ) {
          this.showSetStatusFixedWidget = false;
        }
      }
    },
    updateStatusHistory() {
      this.$emit("updateStatusHistory");
    },
    updateAtAGlance() {
      this.$emit("updateAtAGlance");
      this.updateStatusHistory();
    },
    showFixedWidgetUpload(show) {
      this.isFixedWidgetUploadOpen = show;
    },
    blurTicketInput() {
      this.$refs.ticketInput.blur();
    },
    submitTicketName(event, action) {
      const issueId = parseInt(this.$route.params.issueId);
      // Here we will call the API to save the ticket name
      if (
        action === "ticketSubmit" &&
        event.target.value &&
        this.latestTicketName !== event.target.value
      ) {
        const params = {
          turbineIssueId: issueId,
          ticketName: event.target.value,
        };

        if (this.isEditTicketName) {
          if (this.workOrders.length > 0) {
            const workOrder = this.workOrders.find((workOrder) => {
              return workOrder.work_order_id === this.latestTicketName;
            });
            if (workOrder) {
              params.id = workOrder.id;
            }
            this.saveTicketName(params, "edit");
          }
        } else {
          this.saveTicketName(params);
        }
        // Release focus on the text field after the ticket name is submitted
        this.$nextTick(() => {
          this.$refs.ticketInput.blur();
        });
      } else {
        this.ticketSubmitted = this.latestTicketName;
        if (this.isEditTicketName) {
          this.isEditTicketName = false;
        }
        return;
      }
    },
    callEditTicketName() {
      this.isEditTicketName = true;
      // Focus the submit ticket text field
      this.$nextTick(() => {
        this.$refs.ticketInput.$refs.input.focus();
      });
    },
    // API calls
    async saveTicketName(payload, action) {
      try {
        this.loadingSaveTicketName = true;
        const turbineIssueId = payload.turbineIssueId;
        const params = {
          work_order_id: this.ticketSubmitted,
          created_ts: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          completed_ts: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        };
        // If the ticket name is being edited, the id of the work order being edited is sent
        if (this.isEditTicketName && action === "edit") {
          params.id = payload.id;
        }

        const res = await axios.post(
          `/turbine-issues/${turbineIssueId}/work-orders`,
          params,
        );

        if (res.status === 200) {
          // Set the textfield v-model to the latest ticket name
          this.ticketSubmitted = res.data.work_order_id;
          // Set a static data var to the latest ticket name
          this.latestTicketName = res.data.work_order_id;
        } else {
          const error = {
            name: "saveTicketName",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }

      if (this.isEditTicketName) {
        // Set edit back to false when name is done saving
        this.isEditTicketName = false;
      }
      this.loadingSaveTicketName = false;
    },
    async fetchIssueWorkOrders(turbineIssueId) {
      try {
        this.loadingFetchIssueWorkOrders = true;

        const res = await axios.get(
          `/turbine-issues/${turbineIssueId}/work-orders`,
        );

        if (res.status === 200) {
          this.workOrders = res.data;
          // Set the ticket v-model to the latest ticket name
          this.ticketSubmitted =
            this.workOrders.length > 0
              ? this.workOrders[this.workOrders.length - 1].work_order_id
              : null;
          this.latestTicketName = this.ticketSubmitted;
        } else {
          const error = {
            name: "saveTicketName",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingFetchIssueWorkOrders = false;
    },
  },
  mounted() {
    const turbineIssueId = this.$route.params.issueId;
    document.addEventListener("keydown", this.handleKeydown);
    // Call API to retrieve ticket name
    this.fetchIssueWorkOrders(turbineIssueId);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style lang="scss" scoped>
.recommendations-container {
  position: relative;
  padding: 1rem 1rem 0 1rem;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

table {
  border-collpase: collapse;
  width: 100%;
}
.static-length {
  min-height: 300px;
}

th,
td {
  text-align: left;
  padding: 8px;
}

th {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--v-converterBorders-base); // Change to rgb(var(--v-theme-mediumGray))
}

th:first-child {
  border-right: 1px solid var(--v-converterBorders-base);
  width: 80%;
}

tr {
  border-bottom: 1px dotted var(--v-converterBorders-base); // Change to rgb(var(--v-theme-mediumGray))
}

tr td:first-child {
  border-right: 1px solid var(--v-converterBorders-base);
}

tr:last-child {
  border-bottom: 1px solid var(--v-converterBorders-base);
}

.comment-btn-container {
  position: relative;
}

.recommendation-label {
  label {
    font-family: "Inter", sans-serif;
  }
}

.comment-btn,
.mark-complete-btn {
  border: 1.75px solid var(--v-inverse-base);
  border-radius: 3rem;
  padding: 0.25rem 1rem 0.25rem 1rem;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
}

.comment-btn:hover,
.mark-complete-btn:hover {
  background-color: var(--v-converterBorders-base);
}

.ticket-submitted-badge {
  background-color: var(--v-converterBorders-base);
  border-radius: 1rem;
  padding: 0 1rem 0.25rem 1rem;
  font-size: 1rem;
}

::v-deep {
  .ticket-input .v-text-field__slot input {
    text-align: center;
  }
}

.fixed-widget-container {
  position: relative;
}

.pagination-expression {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.page-number {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
}
.active-page {
  font-weight: bold;
  color: var(--v-primary-base);
}
#page-input {
  outline: none;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 4px;
  width: 2.5rem;
  text-align: center;
  color: white;
}
#page-input::-webkit-outer-spin-button,
#page-input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}
#page-input[type="number"] {
  -moz-appearance: textfield;
}

.previous-disabled,
.next-disabled {
  color: var(--v-gray-base);
}

.table-footer tr,
.table-footer tr td {
  border: none;
}

.table-footer td {
  padding-top: 1rem;
}

// .next-steps-label {
//   font-size: 1rem;
//   font-weight: bold;
// }
</style>
