<template>
  <div>
    <v-card class="comment-card" v-if="displayData.comment">
      <v-card-title>
        <div class="d-flex justify-space-between comment-card__title w-100">
          <div class="comment-card__title__data d-flex">
            <div
              class="comment-card__title__data__name font-weight-light-bold mr-2"
            >
              {{ displayData.name }}
            </div>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="comment-card__text" data-cy="comment-card">
        <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">{{
          displayData.comment
        }}</vue-markdown>
      </v-card-text>
      <div
        v-if="displayData.attachments.length > 0"
        class="d-flex flex-wrap align-center subtitle-2 pb-2 px-2"
      >
        {{ `Attachment${displayData.attachments.length > 1 ? "s" : ""}:` }}
        <div
          v-for="(attachment, index) in displayData.attachments"
          :key="index"
        >
          <div class="pointer align-center">
            <a
              class="ml-1 d-flex text-no-wrap"
              :href="attachment.uri"
              @click.prevent="previewAttachment(attachment)"
              >{{ attachment.name
              }}<span v-if="index + 1 < data.attachments.length">, </span></a
            >
          </div>
        </div>
      </div>
    </v-card>

    <div class="w100" v-else>
      <span class="d-flex flex-wrap align-center">
        <span v-if="displayData.status === 'create'">Issue identified - </span>
        <span class="commenter-name font-weight-light-bold mr-1">{{
          displayData.name
        }}</span>
        changed issue status to
        <b
          class="text-uppercase text-status ml-1"
          v-bind:style="displayData.status | statusStyle"
          >{{ displayData.status }}</b
        >
        <PopUp :content="[displayData.statusComment]" class="status-popup">
          <template v-slot:hover>
            <v-icon v-if="displayData.statusComment" color="gray"
              >mdi-message-text-outline</v-icon
            >
          </template>
          <template v-slot:content>
            {{ displayData.statusComment }}
          </template>
        </PopUp>
      </span>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-v2";
import { statusColors } from "../../helpers/variables";
import PopUp from "@/components/PopUp";
import dayjs from "dayjs";

/**
 * Display card for user comments
 */
export default {
  name: "NewCommentCard",
  components: {
    VueMarkdown,
    PopUp,
  },
  data() {
    return {
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
    };
  },
  props: {
    /**
     * Passed from `EditWidget` containing the comment data to be displayed
     */
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    /**
     * Return formatted date retrieved from the `data` prop
     */
    commentDate() {
      return (
        dayjs(this.displayData.date).format("YYYY-MM-DD HH:mm:ss") + " (UTC)"
      );
    },
    displayData() {
      return this.data;
    },
  },
  methods: {
    previewAttachment(attachment) {
      this.$emit("previewAttachment", attachment);
    },
  },
  filters: {
    statusLabel(val) {
      switch (val) {
        case "awaiting":
          return "awaiting recommendation";
        case "pending":
          return "pending implementation";
        default:
          return val;
      }
    },
    statusStyle(val) {
      let backgroundColor = "";
      let color = "";

      switch (val) {
        case "New":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Dismissed":
          backgroundColor = statusColors.red;
          color = "#242426";
          break;
        case "Not visible":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Unconfirmed (not visible)":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Awaiting recommendation":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Deferred":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Invalidated":
          backgroundColor = statusColors.red;
          color = "#242426";
          break;
        case "Actionable, awaiting recommendation":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Waiting for good mod window":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Implemented, pending verification":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Implemented":
          backgroundColor = statusColors.green;
          color = "#242426";
          break;
        case "Pending implementation":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Pending verification":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "In progress":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Fixed":
          backgroundColor = statusColors.green;
          color = "#242426";
          break;
        case "Fixed + measured":
          backgroundColor = statusColors.green;
          color = "#242426";
          break;
        case "Superseded":
          backgroundColor = statusColors.green;
          color = "gray";
          break;
        default:
          break;
      }

      return {
        backgroundColor,
        color,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.attachment-link {
  color: var(--v-primary-base);
}
.attachment-link:hover {
  text-decoration: underline;
}
.text-status {
  border-radius: 1rem;
  padding: 2px 8px;
  white-space: nowrap;
}
</style>
