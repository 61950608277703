<template>
  <v-form v-model="formValid" ref="issueEvidenceForm">
    <div
      class="d-flex edit-wrapper"
      :class="{ 'justify-center': windowWidth > 1320 }"
    >
      <div class="d-flex flex-column align-items-end edit-evidence-wrapper">
        <div class="edit-evidence-container mr-1 ml-1 mt-2">
          <v-checkbox
            class="mt-0 ml-1 internal-checkbox"
            v-model="internal"
            label="Internal"
            :disabled="loading"
          >
            <template v-slot:label><p class="mb-0 mt-2">Internal</p></template>
          </v-checkbox>

          <v-text-field
            outlined
            dense
            label="Title"
            v-model="title"
            :rules="titleRules"
            :disabled="loading"
            ref="title"
          ></v-text-field>

          <v-textarea
            outlined
            label="Description"
            v-model="description"
            :rules="descriptionRules"
            :disabled="loading"
            ref="description"
          ></v-textarea>
        </div>
        <div class="d-flex mt-3">
          <v-btn outlined class="px-8" @click="cancelEdit">Cancel</v-btn>
          <div class="">
            <v-btn
              color="green"
              class="white--text mr-6 ml-1"
              @click="saveEditedEvidence"
              :disabled="!formValid || loading"
            >
              Save changes
              <div class="spinner-animation">
                <v-progress-circular
                  v-if="loading"
                  size="25"
                  color="gray"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="evidence-container">
        <div class="map-overlay"></div>
        <EvidenceView
          :data="evidenceItem"
          :plotlyHeight="400"
          :plotlyWidth="550"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import EvidenceView from "./EvidenceView";

export default {
  name: "EditEvidenceWidget",
  components: {
    EvidenceView,
  },
  props: {
    evidenceItem: {
      type: Object,
      required: false,
      default: () => {},
    },
    evidenceIndex: {
      type: Number || String,
      required: false,
      default: null,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      internal: false,
      title: "",
      description: "",
      windowWidth: window.innerWidth,
      titleRules: [(v) => !!v || "Title must not be empty."],
      descriptionRules: [
        (v) =>
          !!v ||
          "Description must have some text entered to be applied to this evidence.",
        () =>
          this.description.endsWith(".") ||
          "Description must end with a period.",
      ],
      formValid: false,
      saveDisabled: false,
      editLoading: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapState({
      editDetailsLoading: (state) =>
        state.issueDetail.loading.editTurbineIssueDetails,
      editEvidenceLoading: (state) =>
        state.issueDetail.loading.editIssueEvidence,
      issueDetailLoading: (state) =>
        state.issueDetail.loading.fetchIssueDetailData,
    }),
    loading() {
      return (
        this.editLoading || this.editEvidenceLoading || this.editDetailsLoading
      );
    },
    evidenceItemHasMultipleProperties() {
      if (this.evidenceItem && "current" in this.evidenceItem) {
        return true;
      }
      return false;
    },
    evidenceObj() {
      if (this.evidenceItemHasMultipleProperties) {
        return this.evidenceItem.current
          ? this.evidenceItem.current
          : this.evidenceItem.snapshot;
      } else {
        return this.evidenceItem;
      }
    },
  },
  methods: {
    setInitialData() {
      this.internal = this.evidenceObj.internal;
      this.title = this.evidenceObj.title;
      this.description = this.evidenceObj.description;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    saveEditedEvidence() {
      this.editLoading = true;
      let evidenceObj = {
        id: this.evidenceObj.id,
        title: this.title,
        internal: this.internal,
        description: this.description,
        evidenceIndex: this.evidenceIndex,
      };
      const hasPeriod = this.checkTextEnd(this.description);

      if (this.formValid && hasPeriod) {
        this.$emit("saveEditedEvidence", evidenceObj);
      }
    },
    checkTextEnd(description) {
      if (description) {
        return description.endsWith(".");
      }
    },
    async closeEvidenceAlert(toggle) {
      this.checkTextEnd();
      toggle();
    },
    cancelEdit() {
      this.$emit("cancelEdit");
    },
  },
  watch: {
    evidenceItem: {
      immediate: true,
      handler(data) {
        if (data) {
          this.setInitialData();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.spinner-anchor {
  position: relative;
}

.spinner-animation {
  position: absolute;
  left: 35%;
}

.edit-wrapper {
  overflow-x: auto;
}

.edit-evidence-wrapper {
  background-color: var(--v-editEvidenceBackground-base);
  padding: 15px;
  height: 400px;
}

.edit-evidence-container {
  width: 350px;
  min-width: 150px;
}

.evidence-container {
  position: relative;
  background-color: var(--v-white-base);
}

.map-overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 400px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1025;
}
</style>
