<template>
  <div class="comments viewer-dialog">
    <div class="comments__wrapper" ref="commentsWrapper">
      <div class="viewer-width" v-if="showViewer">
        <DocumentViewer
          :documentData="documentObj"
          :totalPages="pdfTotalPages"
          :currentPage="pdfCurrentPage"
          :docPresent="showViewer"
          @closeViewer="closeViewer"
          @pageUp="setPageUp"
          @pageDown="setPageDown"
          @goToPage="selectPage"
        >
          <PDFPage v-if="pdfPresent">
            <div id="canvas_container" class="canvas-container">
              <canvas id="the-canvas"></canvas>
            </div>
          </PDFPage>
        </DocumentViewer>
      </div>
      <div @click="closeViewer" class="backdrop" v-if="showViewer"></div>
      <div class="spinner-div" v-if="viewerLoading">
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>

      <template v-if="item.isTag">
        <div class="comment-avatar-space"></div>
        <div class="mx-3 d-flex align-center">
          <div class="mr-2">
            <div
              v-if="item.isStaff"
              class="admin-icon-wrapper d-flex align-center justify-center"
            >
              <img src="/img/logo-icon.svg" class="logo" />
            </div>
            <div
              v-else
              class="user-icon-wrapper d-flex align-center justify-center"
              :style="{ backgroundColor: stringToColor(item.name) }"
            >
              {{ item.name | formatName }}
            </div>
          </div>
          <NewCommentCard :data="item" />
        </div>
      </template>

      <template v-else>
        <div class="d-flex align-center">
          <div
            class="comment-avatar font-weight-light-bold mr-4 d-flex align-center justify-center"
            :style="{
              backgroundColor: stringToColor(item.name),
            }"
          >
            {{ item.avatar }}
          </div>
          <div class="comment-card-wrapper">
            <div
              :ref="`comment-${item.id}`"
              :style="{
                border: getBorder(item),
                borderRadius: getRadius(item),
              }"
            >
              <NewCommentCard
                :data="item"
                @previewAttachment="previewAttachment"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import axios from "axios";
import NewCommentCard from "./NewCommentCard";
import DocumentViewer from "@/components/DocumentViewer";
import PDFPage from "@/components/PDFPage";
import PDFPagination from "@/helpers/pdfPagination";
import { findFileExt } from "@/helpers/functions";
import { stringToColor } from "@/helpers/functions";
/**
 * Displays a timeline of issue comments/statuses and an editor to post a comment
 */
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "CommentsTimelineItem",
  components: {
    NewCommentCard,
    DocumentViewer,
    PDFPage,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      stringToColor,
      routeHash: "",
      showViewer: false,
      viewerLoading: false,
      documentObj: {},
      pdfEvent: {},
      pdfPresent: false,
      pdfTotalPages: 0,
      pdfCurrentPage: 1,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.issueDetail.loading.fetchIssueDetailData,
    }),
  },
  methods: {
    previewAttachment(attachment) {
      const fileExt = findFileExt(attachment.uri);
      const html = document.querySelector("html");
      html.style.overflow = "hidden";
      this.documentObj.name = attachment.name;
      this.documentObj.type = fileExt;
      this.documentObj.uri = attachment.uri;

      if (this.documentObj.type === "html") {
        this.viewerLoading = true;
        axios.get(this.documentObj.uri).then((response) => {
          this.documentObj.data = response.data;
          this.showViewer = true;
          this.viewerLoading = false;
        });
      } else if (this.documentObj.type === "pdf") {
        this.viewerLoading = true;
        // eslint-disable-next-line no-undef
        this.getPdf(this.documentObj.uri, pdfjsLib);
      } else {
        this.showViewer = true;
        this.viewerLoading = false;
      }
    },
    async getPdf(url, pdfjsLib) {
      try {
        const pdf = await PDFPagination.loadPdf(url, pdfjsLib);
        this.pdfTotalPages = pdf.numPages;
        this.pdfEvent = pdf;
        this.getPdfPage(1);
        this.pdfPresent = true;
        this.showViewer = true;
        this.viewerLoading = false;
      } catch (error) {
        console.error(
          "Loading pdf has encountered a problem::",
          error.toString(),
        );
      }
    },
    async getPdfPage(page) {
      try {
        await PDFPagination.loadPdfPage(page, this.pdfEvent);
        this.viewerLoading = false;
      } catch (error) {
        console.error("Failing to get and load pdf page::", error.toString());
      }
    },
    async setPageUp() {
      try {
        const page = PDFPagination.pageUp(
          this.pdfTotalPages,
          this.pdfCurrentPage,
        );
        this.pdfCurrentPage = page;
        await this.getPdfPage(page);
      } catch (error) {
        console.error("Page up is failing:: ", error.toString());
      }
    },
    async setPageDown() {
      try {
        const page = PDFPagination.pageDown(
          this.pdfTotalPages,
          this.pdfCurrentPage,
        );
        this.pdfCurrentPage = page;
        await this.getPdfPage(page);
      } catch (error) {
        console.error("Page down is failing:: ", error.toString());
      }
    },
    async selectPage(page) {
      try {
        const pageSelected = PDFPagination.pageSelection(
          page,
          this.pdfTotalPages,
          this.pdfCurrentPage,
        );
        this.pdfCurrentPage = page;
        await this.getPdfPage(pageSelected);
      } catch (error) {
        console.error("Select page is failing:: ", error.toString());
      }
    },
    closeViewer() {
      this.showViewer = false;
      this.pdfPresent = false;
      this.pdfCurrentPage = 1;
      const html = document.querySelector("html");
      html.style.overflow = "auto";
    },
    getBorder(item) {
      const routeHash = this.$route.hash;
      if (routeHash.includes("comment")) {
        const temp = routeHash.split("#");
        const ref = temp[1];

        if (ref === `comment-${item.id}`) {
          setTimeout(() => {
            this.$refs[ref][0].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 600);
          setTimeout(() => {
            this.updateIsNotificationNew(false);
          }, 2300);

          return "2px solid #115B8A";
        }
      } else {
        return "none";
      }
    },
    getRadius(item) {
      const routeHash = this.$route.hash;

      if (routeHash.includes("comment")) {
        const temp = routeHash.split("-");
        const hashId = temp[1];

        if (+hashId === +item.id) {
          return "12px";
        }
      }
    },
  },
  filters: {
    formatName(val) {
      if (!val) {
        return "";
      }

      const nameArr = val.split(" ");

      return `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.comments {
  &__wrapper {
    position: relative;

    &__item {
      z-index: 10;
    }

    &__timeline {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      top: 0;
      left: 72px;
      background-color: var(--v-border-base);
      z-index: 1;
    }
  }

  &__divider {
    border: 1px solid var(--v-black6-base);
  }

  .comment-avatar {
    color: var(--v-white1-base);
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .comment-card-wrapper {
    flex: 1;
    z-index: 2;
  }

  .comment-card-wrapper li::marker {
    list-style-type: none;
  }

  .comment-avatar-space {
    margin-right: 50px;
  }

  .admin-icon-wrapper {
    width: 24px;
    height: 24px;
    background-color: var(--v-black10-base);
    border-radius: 50%;
  }

  .commenter-name {
    color: var(--v-black3-base);
    white-space: nowrap;
  }

  .user-icon-wrapper {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: var(--v-white1-base);
    z-index: 2;
  }

  .logo {
    width: 15px;
  }
}

.viewer-dialog {
  position: relative;
}
.viewer-width {
  width: 100%;
  z-index: 1500;
}
.canvas-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.spinner-div {
  position: absolute;
  z-index: 1550;
  top: 25%;
  left: 50%;
}
</style>
