var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"value":true,"z-index":"1050","hide-overlay":"","max-width":"820px"}},[_c('div',{staticClass:"fixed-status-dialog"},[_c('header',{staticClass:"d-flex justify-space-between dialog-header"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mb-2 mr-2",attrs:{"color":_vm.setOptionStyles('Fixed', 'color')}},[_vm._v(_vm._s(_vm.setOptionStyles("Fixed", "icon")))]),_c('p',[_vm._v("Fixed")])],1),_c('div',{staticClass:"close-icon-container"},[_c('v-icon',{staticClass:"mb-2 pointer",on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)]),_c('div',{staticClass:"fixed-status-grid1 pl-2 pr-2"},[_c('div',{staticClass:"fixed-status-grid1-row-1-col-1"},[_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"mb-0 mr-3 asof-label"},[_vm._v("as of")]),_c('date-picker',{staticClass:"fit-parent-width",class:{
                'date-error': _vm.datetimeInvalid,
              },attrs:{"type":"date","disabled-date":_vm.disabledDates},on:{"input-error":_vm.checkDateValidity,"blur":_vm.checkDateValidity,"close":_vm.checkDateValidity},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)]),_c('div',{staticClass:"fixed-status-grid1-row-1-col-2"},[_c('date-picker',{staticClass:"fit-parent-width",class:{
              'date-error': _vm.datetimeInvalid,
            },attrs:{"type":"time","disabled-time":function (date) { return date <= new Date(_vm.lossesGains.issue_start_ts); }},on:{"input-error":_vm.checkDateValidity,"blur":_vm.checkDateValidity,"close":_vm.checkDateValidity},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('div',{staticClass:"fixed-status-grid1-row-1-col-3"},[_c('div',[_c('v-select',{attrs:{"items":_vm.timeZones,"item-text":"name","item-value":"value","label":"Time zone","solo":"","dense":"","hide-details":""},model:{value:(_vm.timeZone),callback:function ($$v) {_vm.timeZone=$$v},expression:"timeZone"}})],1)]),_c('div',{staticClass:"fixed-status-grid1-row-2-cols-1-2-3"},[_c('div',{staticClass:"d-flex justify-center"},[(_vm.datetimeInvalid)?_c('span',{staticClass:"error--text",staticStyle:{"font-size":"12px"}},[_vm._v("Date/time must be between "+_vm._s(_vm.lossesGains.issue_start_ts)+" (UTC) and "+_vm._s(_vm.dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss") + " (UTC)"))]):_vm._e()])]),_c('div',{staticClass:"fixed-status-grid1-row-3-cols-1-2-3"},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" By "),_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._g(_vm._b({staticClass:"mod-select pl-3",attrs:{"placeholder":"Available mods","items":_vm.turbineModDefs,"item-text":"name","return-object":"","solo":"","dense":"","hide-details":""},model:{value:(_vm.selectedTurbineModDef),callback:function ($$v) {_vm.selectedTurbineModDef=$$v},expression:"selectedTurbineModDef"}},'v-autocomplete',attrs,false),on))]}}])},[(
                  !_vm.selectedTurbineModDef ||
                  (_vm.selectedTurbineModDef &&
                    Object.keys(_vm.selectedTurbineModDef).length === 0)
                )?_c('span',[_vm._v("Please select the modification that fixed this issue")]):(_vm.selectedTurbineModDef)?_c('span',[_vm._v(_vm._s(_vm.selectedTurbineModDef.description))]):_vm._e()]),(
                _vm.selectedTurbineModDef &&
                _vm.selectedTurbineModDef.requires_value === true
              )?_c('v-text-field',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"label":_vm.valueLabel,"solo":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.modValue),callback:function ($$v) {_vm.modValue=$$v},expression:"modValue"}}):_vm._e(),(
                _vm.selectedTurbineModDef &&
                _vm.selectedTurbineModDef.id &&
                (_vm.selectedTurbineModDef.id === 16 ||
                  _vm.selectedTurbineModDef.id === 17)
              )?_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"value-input pl-2",attrs:{"solo":"","dense":"","hide-details":"","clearable":"","label":"Mod notes"},model:{value:(_vm.modNotes),callback:function ($$v) {_vm.modNotes=$$v},expression:"modNotes"}},'v-text-field',attrs,false),on))]}}],null,false,2145086200)},[_c('span',[_vm._v("Please describe the modification that fixed this issue")])]):_vm._e()],1)])]),_c('div',{staticClass:"d-flex align-center justify-space-between issue-status"},[_c('div',{staticClass:"d-flex align-center mr-2"},[_c('div',{staticClass:"d-flex"},[(
                _vm.latestStatus.definition_name_external ===
                  'Actionable, awaiting recommendation' &&
                !_vm.userHasInternalAccess
              )?_c('div',{staticClass:"d-flex ml-2"},[_vm._v(" The system is generating a recommendation and the status will be updated when it's ready. ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[(
                    _vm.latestStatus.definition_name_internal ===
                    'Pending verification'
                  )?_c('div',{staticClass:"d-flex"},[(!_vm.isPreviousRecommendation && !_vm.isNewRecommendation)?_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"disabled":_vm.isFixed,"color":"primary"},on:{"click":_vm.verifyAsFixed}},[_vm._v(" Verify as fixed ")]):_vm._e(),(!_vm.isFixed && !_vm.isNewRecommendation)?_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"disabled":_vm.isPreviousRecommendation ||
                      !_vm.newestPendingImplementationStatus,"color":"primary"},on:{"click":_vm.previousRecommendation}},[_vm._v(" Re-recommend previous recommendation ")]):_vm._e(),(!_vm.isFixed && !_vm.isPreviousRecommendation)?_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"disabled":_vm.isNewRecommendation,"color":"primary"},on:{"click":_vm.newRecommendation}},[_vm._v(" Add new recommendation ")]):_vm._e()],1):_vm._e()])])])]),(_vm.isPreviousRecommendation)?_c('div',[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(" "+_vm._s(_vm.newestPendingImplementationStatus.turbine_recommendation .turbine_mod_definition.description)+" ")]),_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(_vm.newestPendingImplementationStatus.turbine_recommendation.notes))]),(
              _vm.newestPendingImplementationStatus &&
              _vm.newestPendingImplementationStatus.attachments.length > 0
            )?_c('div',{staticClass:"d-flex align-center subtitle-2"},[_c('div',[_vm._v(" Attachments: "),_vm._l((_vm.newestPendingImplementationStatus.attachments),function(attachment,index){return _c('div',{key:index},[_c('div',{staticClass:"pointer d-flex align-center"},[_c('a',{staticClass:"ml-1",attrs:{"href":attachment.uri,"download":""}},[_vm._v(_vm._s(attachment.name))]),_c('a',{staticClass:"download-icon",attrs:{"href":attachment.uri,"download":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"25px"}},[_vm._v("mdi-file-download")])],1)])])})],2)]):_vm._e()],1):_vm._e()]),(_vm.latestStatus.attachments.length > 0)?_c('div',{staticClass:"d-flex align-center subtitle-2 pa-4"},[_vm._v(" Attachments: "),_vm._l((_vm.latestStatus.attachments),function(attachment,index){return _c('div',{key:index},[_c('div',{staticClass:"pointer d-flex align-center"},[_c('a',{staticClass:"ml-1",attrs:{"href":attachment.uri,"download":""}},[_vm._v(_vm._s(attachment.name))]),_c('a',{staticClass:"download-icon",attrs:{"href":attachment.uri,"download":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"25px"}},[_vm._v("mdi-file-download")])],1)])])})],2):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center justify-space-between pa-4 w100"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-textarea',_vm._g(_vm._b({staticStyle:{"max-width":"50%"},attrs:{"solo":"","dense":"","clearable":"","rows":"1","label":"Comment","hide-details":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}},'v-textarea',attrs,false),on))]}}])},[_c('span',[_vm._v("Any additional comments about fixing the issue that would be useful to save")])]),_c('div',[_c('div',{staticClass:"upload-btn-container"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleUploadDialog(true)}}},[_c('img',{staticClass:"upload-img mr-1",attrs:{"src":"/img/file-upload.svg"}}),_vm._v(" Upload attachments ")]),(_vm.showDialog)?_c('UploadDialog',{attrs:{"isNotSiteDocument":true,"isFixedStatusWidget":true,"title":"Upload attachment"},on:{"setShowDialog":function($event){return _vm.toggleUploadDialog(true)},"setFile":_vm.setFile}}):_vm._e()],1)]),(_vm.showDialog)?_c('div',{staticClass:"backdrop",on:{"click":function($event){return _vm.toggleUploadDialog(false)}}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center mr-4"},[_c('v-btn',{staticClass:"px-3 mr-1",attrs:{"outlined":""},on:{"click":_vm.cancelStatusChange}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"px-4 white--text",attrs:{"color":"green","loading":_vm.saveStatusLoading,"disabled":_vm.shouldDisableSaveButton},on:{"click":_vm.saveIssueStatus}},[_vm._v(" Save")])],1)])]),(_vm.attachments && _vm.attachments.length > 0)?_c('div',{staticClass:"d-flex align-center flex-wrap attachments-container"},_vm._l((_vm.attachments),function(attachment,index){return _c('div',{key:index,staticClass:"pl-2 attachment"},[_vm._v(" "+_vm._s(attachment.name)+" "),_c('v-icon',{on:{"click":function($event){return _vm.removeAttachment(attachment)}}},[_vm._v("mdi-close")])],1)}),0):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }