<template>
  <div>
    <v-dialog :value="true" z-index="1050" hide-overlay max-width="820px">
      <div class="fixed-status-dialog">
        <header class="d-flex justify-space-between dialog-header">
          <div class="d-flex align-center">
            <v-icon
              class="mb-2 mr-2"
              :color="setOptionStyles('Fixed', 'color')"
              >{{ setOptionStyles("Fixed", "icon") }}</v-icon
            >
            <p>Fixed</p>
          </div>
          <div class="close-icon-container">
            <v-icon class="mb-2 pointer" @click="closeDialog">mdi-close</v-icon>
          </div>
        </header>
        <div class="fixed-status-grid1 pl-2 pr-2">
          <div class="fixed-status-grid1-row-1-col-1">
            <div class="d-flex align-center">
              <label class="mb-0 mr-3 asof-label">as of</label>
              <date-picker
                type="date"
                v-model="date"
                :class="{
                  'date-error': datetimeInvalid,
                }"
                class="fit-parent-width"
                :disabled-date="disabledDates"
                @input-error="checkDateValidity"
                @blur="checkDateValidity"
                @close="checkDateValidity"
              />
            </div>
          </div>
          <div class="fixed-status-grid1-row-1-col-2">
            <date-picker
              type="time"
              v-model="date"
              :class="{
                'date-error': datetimeInvalid,
              }"
              class="fit-parent-width"
              :disabled-time="
                (date) => date <= new Date(lossesGains.issue_start_ts)
              "
              @input-error="checkDateValidity"
              @blur="checkDateValidity"
              @close="checkDateValidity"
            />
          </div>
          <div class="fixed-status-grid1-row-1-col-3">
            <div>
              <v-select
                v-model="timeZone"
                :items="timeZones"
                item-text="name"
                item-value="value"
                label="Time zone"
                solo
                dense
                hide-details
              />
            </div>
          </div>
          <div class="fixed-status-grid1-row-2-cols-1-2-3">
            <div class="d-flex justify-center">
              <span
                v-if="datetimeInvalid"
                class="error--text"
                style="font-size: 12px"
                >Date/time must be between
                {{ lossesGains.issue_start_ts }} (UTC) and
                {{
                  dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss") + " (UTC)"
                }}</span
              >
            </div>
          </div>
          <div class="fixed-status-grid1-row-3-cols-1-2-3">
            <div class="d-flex align-center">
              By
              <v-tooltip top open-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-autocomplete
                    v-model="selectedTurbineModDef"
                    v-bind="attrs"
                    v-on="on"
                    placeholder="Available mods"
                    :items="turbineModDefs"
                    class="mod-select pl-3"
                    item-text="name"
                    return-object
                    solo
                    dense
                    hide-details
                  />
                </template>
                <span
                  v-if="
                    !selectedTurbineModDef ||
                    (selectedTurbineModDef &&
                      Object.keys(selectedTurbineModDef).length === 0)
                  "
                  >Please select the modification that fixed this issue</span
                >
                <span v-else-if="selectedTurbineModDef">{{
                  selectedTurbineModDef.description
                }}</span>
              </v-tooltip>

              <v-text-field
                v-if="
                  selectedTurbineModDef &&
                  selectedTurbineModDef.requires_value === true
                "
                v-model="modValue"
                class="ml-2"
                :label="valueLabel"
                style="max-width: 150px"
                solo
                dense
                hide-details
                clearable
              >
              </v-text-field>

              <!-- If selected mod is 'other' then require notes, id 16 or 17 -->
              <v-tooltip
                top
                open-delay="200"
                v-if="
                  selectedTurbineModDef &&
                  selectedTurbineModDef.id &&
                  (selectedTurbineModDef.id === 16 ||
                    selectedTurbineModDef.id === 17)
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="modNotes"
                    solo
                    dense
                    hide-details
                    clearable
                    label="Mod notes"
                    class="value-input pl-2"
                  ></v-text-field>
                </template>
                <span
                  >Please describe the modification that fixed this issue</span
                >
              </v-tooltip>
            </div>
          </div>
        </div>

        <div class="d-flex align-center justify-space-between issue-status">
          <!-- Not sure where this would go with the new set up for fixed, leaving here for now -->
          <div class="d-flex align-center mr-2">
            <div class="d-flex">
              <div
                class="d-flex ml-2"
                v-if="
                  latestStatus.definition_name_external ===
                    'Actionable, awaiting recommendation' &&
                  !userHasInternalAccess
                "
              >
                The system is generating a recommendation and the status will be
                updated when it's ready.
              </div>
              <div class="d-flex">
                <div class="d-flex">
                  <div
                    class="d-flex"
                    v-if="
                      latestStatus.definition_name_internal ===
                      'Pending verification'
                    "
                  >
                    <v-btn
                      v-if="!isPreviousRecommendation && !isNewRecommendation"
                      :disabled="isFixed"
                      color="primary"
                      class="px-3 mr-1"
                      @click="verifyAsFixed"
                    >
                      Verify as fixed
                    </v-btn>
                    <v-btn
                      v-if="!isFixed && !isNewRecommendation"
                      :disabled="
                        isPreviousRecommendation ||
                        !newestPendingImplementationStatus
                      "
                      color="primary"
                      class="px-3 mr-1"
                      @click="previousRecommendation"
                    >
                      Re-recommend previous recommendation
                    </v-btn>
                    <v-btn
                      v-if="!isFixed && !isPreviousRecommendation"
                      :disabled="isNewRecommendation"
                      color="primary"
                      class="px-3 mr-1"
                      @click="newRecommendation"
                    >
                      Add new recommendation
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isPreviousRecommendation">
            <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">
              {{
                newestPendingImplementationStatus.turbine_recommendation
                  .turbine_mod_definition.description
              }}
            </vue-markdown>
            <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">{{
              newestPendingImplementationStatus.turbine_recommendation.notes
            }}</vue-markdown>
            <div
              v-if="
                newestPendingImplementationStatus &&
                newestPendingImplementationStatus.attachments.length > 0
              "
              class="d-flex align-center subtitle-2"
            >
              <div>
                Attachments:
                <div
                  v-for="(
                    attachment, index
                  ) in newestPendingImplementationStatus.attachments"
                  :key="index"
                >
                  <div class="pointer d-flex align-center">
                    <a class="ml-1" :href="attachment.uri" download>{{
                      attachment.name
                    }}</a>
                    <a :href="attachment.uri" download class="download-icon">
                      <v-icon class="mr-1" size="25px"
                        >mdi-file-download</v-icon
                      ></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="latestStatus.attachments.length > 0"
          class="d-flex align-center subtitle-2 pa-4"
        >
          Attachments:
          <div
            v-for="(attachment, index) in latestStatus.attachments"
            :key="index"
          >
            <div class="pointer d-flex align-center">
              <a class="ml-1" :href="attachment.uri" download>{{
                attachment.name
              }}</a>
              <a :href="attachment.uri" download class="download-icon">
                <v-icon class="mr-1" size="25px">mdi-file-download</v-icon></a
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="d-flex align-center justify-space-between pa-4 w100">
            <v-tooltip bottom open-delay="200">
              <template v-slot:activator="{ on, attrs }">
                <v-textarea
                  v-bind="attrs"
                  v-on="on"
                  v-model="comment"
                  solo
                  dense
                  clearable
                  rows="1"
                  label="Comment"
                  hide-details
                  style="max-width: 50%"
                ></v-textarea>
              </template>
              <span
                >Any additional comments about fixing the issue that would be
                useful to save</span
              >
            </v-tooltip>
            <div>
              <div class="upload-btn-container">
                <v-btn color="primary" @click="toggleUploadDialog(true)">
                  <img src="/img/file-upload.svg" class="upload-img mr-1" />
                  Upload attachments
                </v-btn>
                <UploadDialog
                  v-if="showDialog"
                  :isNotSiteDocument="true"
                  :isFixedStatusWidget="true"
                  title="Upload attachment"
                  @setShowDialog="toggleUploadDialog(true)"
                  @setFile="setFile"
                />
              </div>
            </div>

            <div
              @click="toggleUploadDialog(false)"
              class="backdrop"
              v-if="showDialog"
            ></div>
          </div>
          <div class="d-flex align-center">
            <div class="d-flex align-center mr-4">
              <v-btn class="px-3 mr-1" outlined @click="cancelStatusChange"
                >Cancel</v-btn
              >
              <v-btn
                color="green"
                class="px-4 white--text"
                :loading="saveStatusLoading"
                :disabled="shouldDisableSaveButton"
                @click="saveIssueStatus"
              >
                Save</v-btn
              >
            </div>
          </div>
        </div>
        <div
          v-if="attachments && attachments.length > 0"
          class="d-flex align-center flex-wrap attachments-container"
        >
          <div
            v-for="(attachment, index) in attachments"
            :key="index"
            class="pl-2 attachment"
          >
            {{ attachment.name }}
            <v-icon @click="removeAttachment(attachment)">mdi-close</v-icon>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import { issueStatusOptions, clickCountLimit } from "@/helpers/variables";
import VueMarkdown from "vue-markdown-v2";
import UploadDialog from "@/components/UploadDialog";
import OpenIndicator from "@/components/OpenIndicator.vue";
import { DateTime } from "luxon";
import timeZones from "@/helpers/timezones";
import "vue2-datepicker/index.css";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

/**
 * Display widget for issue statuses dropdown
 */

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
// vueSelect.props.components.default = () => ({ OpenIndicator });

export default {
  name: "NewFixedStatusWidget",
  components: {
    DatePicker,
    VueMarkdown,
    UploadDialog,
  },
  emits: ["updateStatus"],
  props: {
    /**
     * Determines whether or not a user has write access
     *
     * This will enable the user to change the issue status if true or disable if false
     */
    userHasWriteAccess: {
      type: Boolean,
      default: false,
      required: true,
    },
    userHasInternalAccess: {
      type: Boolean,
      default: false,
      required: true,
    },
    lossPct: {
      type: String,
      default: "",
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Holds the value of the date picker for status selections that require a timestamp
       */
      date: null,
      /**
       * List of possible icons for each issue status
       */
      statusStyleOptions: issueStatusOptions,
      /**
       * Determines whether or not the datepicker is visible
       */
      showDatepicker: false,
      /**
       * Determines if the save button should be shown next to a status
       */
      showSave: false,
      /**
       * Determines if the latest issue status date should be shown or not
       */
      showLastUpdated: true,
      /**
       * Holds the value for the latest issue status date
       */
      dateLastUpdated: null,
      /**
       * Holds the value of an issue status icon color
       */
      iconColor: "",
      /**
       * Holds the value of an issue status icon
       */
      iconStyle: "",
      /**
       * Sets the initial value of the issue status dropdown to the latest status
       */
      selected: {},
      issueSelected: {
        name: "Select issue...",
      },
      /**
       * Determines the loading state when selecting to assign a different status to an issue
       */
      saveStatusLoading: false,
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      recommendationSelection: null,
      isFixed: false,
      isPreviousRecommendation: false,
      isNewRecommendation: false,
      isSuperseded: false,
      attachments: [],
      timeZones,
      timeZone: null,
      showComment: false,
      comment: "",
      datetimeInvalid: false,
      dateErrMsg: "",
      turbineIssuesLoading: false,
      selectedTurbineModDef: {},
      modNotes: "",
      modValue: "",
      OpenIndicator,
      dayjs,
      showViewer: false,
      viewerLoading: false,
      documentObj: {},
      pdfEvent: {},
      pdfPresent: false,
      pdfTotalPages: 0,
      pdfCurrentPage: 1,
    };
  },
  computed: {
    ...mapState({
      updateIssueStatusResponse: (state) =>
        state.issueDetail.updateIssueStatusResponse,
      statusDataloading: (state) =>
        state.issueDetail.loading.getIssueStatusData,
      issueStatuses: (state) => state.issueDetail.issueStatuses,
      statusHistory: (state) => state.issueDetail.statusHistory,
      latestStatus: (state) => state.issueDetail.latestStatus,
      cancel: (state) => state.issueDetail.cancel,
      lossesGains: (state) => state.issueDetail.lossesGains,
      issueData: (state) => state.issueDetail.issueData,
      confirmedIssues: (state) => state.issues.confirmedIssues,
      turbineIssues: (state) => state.issues.turbineIssues,
      turbineModDefs: (state) => state.issueDetail.turbineModDefs,
      suggestedRecommendation: (state) =>
        state.issueDetail.suggestedRecommendation,
      clickCount: (state) => state.app.clickCount,
    }),
    shouldDisableSaveButton() {
      const isFixedAndNoModSelected =
        this.selected?.name_external === "Fixed" &&
        this.selectedTurbineModDef &&
        Object.keys(this.selectedTurbineModDef).length === 0;
      const isOtherModAndNoNotes =
        (this.selectedTurbineModDef?.id === 16 ||
          this.selectedTurbineModDef?.id === 17) &&
        this.modNotes === "";
      const isModRequiresValueAndNoValue =
        this.selectedTurbineModDef?.requires_value === 1 &&
        (this.modValue === "" || this.modValue === null);

      return (
        this.saveStatusLoading ||
        this.datetimeInvalid ||
        (this.isSuperseded && this.issueSelected.name === "Other issue") ||
        this.dateRequiredAndIncomplete ||
        isFixedAndNoModSelected ||
        (this.selectedTurbineModDef &&
          (isOtherModAndNoNotes || isModRequiresValueAndNoValue))
      );
    },
    valueLabel() {
      return this.selectedTurbineModDef?.value_units
        ? `Value (${this.selectedTurbineModDef?.value_units})`
        : "Value";
    },
    dateRequiredAndIncomplete() {
      return (
        this.selected.require_timestamp_input &&
        (this.date === null || this.timeZone === null)
      );
    },
    statuses() {
      if (this.issueStatuses?.length > 0) {
        return this.issueStatuses;
      } else {
        return ["Loading..."];
      }
    },
    turbineConfirmedIssues() {
      const issueId = +this.$route.params.issueId;

      if (
        issueId &&
        this.confirmedIssues?.length > 0 &&
        this.issueData &&
        !this.latestStatus.superseded_by_turbine_issue_id
      ) {
        // Make sure that this issue can not supersede itself and
        // can not be superseded by an issue that it has superseded
        const supersededIssuesArr = [];
        const confirmedArr = [];

        for (const item of this.issueData.superseded_turbine_issues) {
          supersededIssuesArr.push(item.id);
        }
        for (const issue of this.confirmedIssues) {
          if (issueId !== issue.id && !supersededIssuesArr.includes(issue.id)) {
            confirmedArr.push(issue);
          }
        }
        return confirmedArr;
      } else {
        return [];
      }
    },
    /**
     * Returns latest status value for a watcher
     *
     * The watcher updates the latest status and date when the latest status value changes
     */
    watchLatestStatus() {
      return this.latestStatus;
    },
    /**
     * Disables the status select dropdown if the user's role does not have admin or write access
     */
    selectDisabled() {
      return (
        this.role &&
        (this.role.includes("write") || this.role.includes("admin"))
      );
    },
    isPendingAndNotAdmin() {
      return (
        this.latestStatus.definition_name_internal === "Pending verification" &&
        !this.userHasInternalAccess
      );
    },
    /**
     * Returns the last status with definition name "Pending implementation"
     */
    newestPendingImplementationStatus() {
      return this.statusHistory
        .slice()
        .reverse()
        .find(
          (status) =>
            status.definition_name_internal === "Pending implementation",
        );
    },
    wasSuperseded() {
      if (this.latestStatus?.superseded_by_turbine_issue_id) {
        return true;
      } else {
        return false;
      }
    },
    supersedingIssue() {
      if (this.wasSuperseded) {
        return this.latestStatus;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions({
      updateIssueStatus: "issueDetail/updateIssueStatus",
      updateRecommendationSelection:
        "issueDetail/updateRecommendationSelection",
      clickedCancel: "issueDetail/clickedCancel",
      getIssueStatusData: "issueDetail/getIssueStatusData",
      getTurbineModDefs: "issueDetail/getTurbineModDefs",
      incrementClickCount: "app/incrementClickCount",
    }),
    /**
     * Sets the icon styles for the status icons in the dropdown
     */
    setOptionStyles(status, property) {
      let styleOption = {
        color: "",
        icon: "",
      };

      styleOption = this.statusStyleOptions.find(
        (item) => item.title === status,
      );

      if (styleOption && property === "color") {
        return styleOption.color ? styleOption.color : "";
      }
      if (styleOption && property === "icon") {
        return styleOption.icon ? styleOption.icon : "";
      }
    },
    /**
     * Saves and replaces the latest status with the newly saved status
     *
     * Triggers a custom event to reflect the change and update the status list
     */
    async saveIssueStatus() {
      this.saveStatusLoading = true;
      this.turbineIssuesLoading = true;
      // Set timezone of selected time without converting time to new timezone offset
      const selectedTime = DateTime.fromJSDate(this.date);
      var rezoned = selectedTime.setZone(this.timeZone, {
        keepLocalTime: true,
      });
      // Then convert the rezoned time to UTC
      const utc = rezoned.setZone("UTC");
      let updateObj = {
        issueId: this.$route.params.issueId,
        statusDefId: 8, // Status definition id of the FIXED status
      };

      if (this.date && !this.datetimeInvalid) {
        updateObj.ts = utc.toFormat("yyyy-LL-dd HH:mm:ss");
      }

      if (this.attachments.length > 0) {
        updateObj.attachment = this.attachments;
        updateObj.kind = "status";
      }

      if (this.comment !== "") {
        updateObj.comment = this.comment;
      }

      updateObj.mod = this.selectedTurbineModDef;
      updateObj.turbine_mod_id = this.selectedTurbineModDef?.id;
      if (this.modValue !== null || !isNaN(this.modValue)) {
        updateObj.value = this.modValue;
      }
      if (this.modNotes !== "") {
        updateObj.modNotes = this.modNotes;
      }

      this.turbineIssuesLoading = true;

      // Data sent to Google Analytics
      let eventLabel = `issue_status_changed_to_${this.selected.name_internal}`;
      if (this.clickCount < clickCountLimit) {
        this.incrementClickCount();
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: eventLabel.toLowerCase(),
          value: this.clickCount,
        });
      }

      await this.updateIssueStatus(updateObj);
      /**
       * Tells the parent component to update the status list to include the newly saved status
       *
       * Also updates the latest status to reflect the newly saved status
       */
      await this.getIssueStatusData({
        issueId: this.$route.params.issueId,
      });
      this.$emit("updateAtAGlance");
      this.saveStatusLoading = false;
    },
    /**
     * Cancel status selection and revert to latest status being shown
     */
    cancelStatusChange() {
      this.attachments = null;
      this.selected = this.latestStatus;
      this.issueSelected = { name: "Select issue..." };
      this.isSuperseded = false;
      this.showSave = false;
      this.showLastUpdated = true;
      this.isFixed = false;
      this.isPreviousRecommendation = false;
      this.isNewRecommendation = false;
      this.datetimeInvalid = false;
      this.date = null;
    },
    verifyAsFixed() {
      this.showSave = true;
      this.showLastUpdated = false;
      this.selected = this.statuses.find((status) => status.id === 8); // Fixed id
      this.isFixed = true;
    },
    previousRecommendation() {
      this.showSave = true;
      this.showLastUpdated = false;
      this.selected = this.statuses.find((status) => status.id === 5); // Pending implementation id
      this.isPreviousRecommendation = true;
    },
    newRecommendation() {
      this.showSave = false;
      this.showLastUpdated = false;
      this.selected = this.statuses.find((status) => status.id === 5); // Pending implementation id
      this.$nextTick(() => {
        this.updateRecommendationSelection("New recommendation");
      });

      this.isNewRecommendation = true;
      this.$emit("isNewRecommendation", true);
    },
    toggleUploadDialog(showDialog) {
      //  this.showDialog = showDialog;
      this.$emit("openUploadDialog", showDialog);
    },
    setFile(chosenFile) {
      this.attachments = chosenFile;
    },
    removeAttachment(attachment) {
      this.attachments = this.attachments.filter((item) => item !== attachment);
    },
    checkDateValidity(value) {
      if (
        typeof value == "string" &&
        new Date(value).toString() === "Invalid Date"
      ) {
        this.datetimeInvalid = true;
      } else if (
        value &&
        (new Date(value) < new Date(this.lossesGains.issue_start_ts) ||
          new Date(value) > new Date())
      ) {
        this.datetimeInvalid = true;
      } else if (
        this.date &&
        this.date < new Date(this.lossesGains.issue_start_ts)
      ) {
        this.datetimeInvalid = true;
      } else {
        this.datetimeInvalid = false;
      }
    },
    disabledDates(date) {
      return (
        date < new Date(this.lossesGains.issue_start_ts) || date > new Date()
      );
    },
    closeDialog() {
      this.$emit("closeFixedStatusDialog");
    },
  },
  async beforeMount() {
    await this.getTurbineModDefs();
  },
  watch: {
    selected: {
      handler(value) {
        if (value) {
          if (this.selected.require_timestamp_input) {
            if (this.selected === this.latestStatus) {
              this.showDatepicker = false;
              this.showSave = false;
            } else {
              this.showDatepicker = true;
            }
          } else {
            this.showDatepicker = false;
          }

          if (this.selected === this.latestStatus) {
            this.showSave = false;
            this.showComment = false;
            this.showLastUpdated = true;
          } else if (!this.isNewRecommendation) {
            this.showSave = true;
            this.showComment = true;
            this.showLastUpdated = false;
          }
          if (
            this.selected.definition_name_internal !== "Pending implementation"
          ) {
            this.updateRecommendationSelection(null);
          }
          if (
            this.selected.name_external === "Superseded" &&
            this.selected.name_external !== this.latestStatus
          ) {
            this.isSuperseded = true;
            if (!this.wasSuperseded) {
              this.$emit("statusSelected", this.selected);
            }
          } else {
            this.isSuperseded = false;
          }
        }
      },
    },
    cancel: {
      handler(value) {
        if (value) {
          this.showSave = false;
          this.showComment = false;
          this.cancelStatusChange();
          this.clickedCancel(false);
        }
      },
    },
    watchLatestStatus: {
      immediate: true,
      handler(status) {
        if (status && Object.keys(status).length > 0) {
          const updateDate = new Date(status.created_ts);
          const localDateForm = updateDate.toISOString();
          const splitDate = localDateForm.split("T");
          const latestDateDisplay = `Last updated ${splitDate[0]}`;

          this.selected = status;
          this.dateLastUpdated = latestDateDisplay;
          this.showSave = false;
          this.saveStatusLoading = false;
        }
      },
    },
    suggestedRecommendation: {
      immediate: true,
      handler(data) {
        if (
          this.suggestedRecommendation &&
          Object.keys(this.suggestedRecommendation).length > 0
        ) {
          this.selectedTurbineModDef =
            this.suggestedRecommendation?.turbine_mod_def;
          this.modValue = this.suggestedRecommendation.value;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
.fixed-status-dialog {
  background-color: var(--v-blueBackground-base);
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 8px;
  max-width: 820px;
}

header {
  color: var(--v-black5-base); // When vuetify3 live, change to --v-theme-black5

  p {
    margin-bottom: 0.5rem;
    font-size: 1.15rem;
    font-weight: 450;
    color: var(--v-text-base);
  }
}

.fixed-status-grid1 {
  display: grid;
  grid-template-columns: 1.425fr repeat(5, 1fr);
  grid-template-rows: 39px 20px 39px;
  row-gap: 0.25rem;
  column-gap: 0.5rem;
  height: 100%;
}
.fixed-status-grid1-row-1-col-1 {
  label {
    white-space: nowrap;
  }
  grid-column-start: 1;
  grid-column-end: 3;
}
.fit-parent-width {
  width: 100% !important;
}
.fixed-status-grid1-row-1-col-2 {
  grid-column-start: 3;
  grid-column-end: 5;
}
.fixed-status-grid1-row-1-col-3 {
  grid-column-start: 5;
  grid-column-end: 7;
}
.fixed-status-grid1-row-2-cols-1-2-3 {
  grid-column-start: 1;
  grid-column-end: 7;
}
.fixed-status-grid1-row-3-cols-1-2-3 {
  grid-column-start: 1;
  grid-column-end: 7;
}
.mod-select,
.value-input {
  max-width: 360px;
}
.mod-markdown p {
  margin-bottom: 0;
}

.upload-btn-container {
  position: relative;
}

/*---------------PREVIOUS CSS-----------------------*/

.issue-status {
  position: relative;
  padding: 0.45rem;
  border-radius: 8px;

  .label {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: "Museo Sans Rounded";
  }

  .issue-status-select {
    width: 280px;
  }

  .by-text {
    font-size: 14px;
  }

  .last-update-date {
    color: var(--v-black5-base);
  }

  .issue-select {
    width: 280px;
  }

  .superseding-issue-link {
    font-size: 14px;
  }

  .superseding-issue-link:hover {
    color: rgba(50, 93, 171);
    text-decoration: underline;
  }
}

::v-deep {
  .date-error {
    .mx-input {
      border-color: red;
    }
  }
}
::v-deep {
  --vs-controls-color: var(--v-text-base);
  --vs-border-color: var(--v-text-base);
  --vs-dropdown-bg: var(--v-background-base);
  --vs-dropdown-color: var(--v-text-base);
  --vs-dropdown-option-color: var(--v-text-base);
  --vs-selected-color: var(--v-text-base);
  --vs-dropdown-option--active-bg: var(--v-hover-base);
  --vs-dropdown-option--active-color: var(--v-text-base);
}
::v-deep {
  .vs__dropdown-toggle {
    border: none;
  }
  .vs__dropdown-menu {
    border: none !important;
    background-color: var(--v-secondary-base);
  }
  .vs__dropdown-toggle[aria-expanded="true"] {
    .vs__open-indicator {
      rotate: 90deg;
    }
  }

  .issue-status-select {
    .vs__dropdown-toggle {
      background-color: var(--v-secondary-base);
      cursor: pointer;
    }
  }
  .theme--dark .vs__dropdown-menu {
    background-color: var(--v-black10-base) !important;
    background: var(--v-black10-base) !important;
  }
}

::v-deep {
  .issue-select {
    .vs__dropdown-toggle {
      background-color: var(--v-secondary-base);
      cursor: pointer;
      max-height: 2.3rem;
    }
    .vs__dropdown-toggle[aria-expanded="true"] {
      .vs__selected {
        color: var(--v-text-base);
      }
    }
    .vs__search {
      width: 1px;
    }
    .vs__selected {
      line-height: 1rem;
      margin: 0;
    }
    .vs__dropdown-menu {
      overflow-x: hidden;
      margin: 0;
      padding: 0;
    }
  }
}

.unconfirmed-text {
  font-size: 1.125rem;
  color: $red1;
}

.viewer-dialog {
  position: relative;
}
.viewer-width {
  width: 100%;
  z-index: 1500;
}
.canvas-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.spinner-div {
  position: absolute;
  z-index: 1550;
  top: 25%;
  left: 50%;
}

.attachments-container {
  max-width: 788px;
  height: 4rem;
  overflow-y: auto;
  overflow-x: hidden;
  // Styles for Firefox
  scrollbar-color: var(--v-border-base) var(--v-background-base);
  scrollbar-width: thin;
}
// Styles for Chrome, Edge, Safari,
.attachments-container::-webkit-scrollbar {
  width: 0.35rem;
}
.attachments-container::-webkit-scrollbar-track {
  background: var(--v-background-base);
}
.attachments-container::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 8px;
}
.attachment {
  white-space: nowrap;
}

@media (min-width: 1750px) {
  dialog {
    left: -800px;
  }
}

@media (max-width: 1264px) {
  dialog {
    left: -550px;
    bottom: 170px;
  }
}
</style>
