<template>
  <div>
    <dialog open>
      <header class="d-flex justify-space-between">
        <p>Create a comment</p>
        <v-icon class="mb-1 pointer" @click="closeDialog">mdi-close</v-icon>
      </header>
      <NewCommentEditor
        :attachments="attachments"
        @postComment="postComment"
        @openUploadDialog="openUploadDialog"
        @removeAttachment="removeAttachment"
        @emptyAttachments="emptyAttachments"
      />
    </dialog>
  </div>
</template>

<script>
import NewCommentEditor from "./NewCommentEditor";

export default {
  name: "NewCreateCommentDialog",
  components: {
    NewCommentEditor,
  },
  props: {
    attachments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    postComment() {
      this.$emit("postComment");
    },
    openUploadDialog() {
      this.$emit("openUploadDialog", true);
    },
    removeAttachment(attachment) {
      this.$emit("removeAttachment", attachment);
    },
    emptyAttachments() {
      this.$emit("emptyAttachments");
    },
    closeDialog() {
      this.$emit("closeCreateCommentDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
dialog {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 37rem;
  z-index: 1050;
  border: none;
  border-radius: 8px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.75);
  margin: 0;
  overflow: hidden;
  background-color: var(--v-secondary-base);
  padding: 0.5rem 1rem 1rem 1rem;
}

header {
  color: var(--v-text-base); // When vuetify3 live, change to --v-theme-text

  p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 450;
  }
}
</style>
