<template>
  <div data-cy="comment-editor">
    <div class="editor" @keydown.ctrl.enter="handleCommentClick">
      <v-md-editor
        v-model="text"
        mode="edit"
        height="250px"
        left-toolbar="bold italic strikethrough ul ol link image"
        data-cy="textarea"
      />
    </div>
    <div class="d-flex justify-space-between mt-3">
      <div class="d-flex">
        <v-btn
          color="primary"
          class="px-4 mr-2 white--text"
          :disabled="actionLoading"
          @click="toggleUploadDialog(true)"
          >Upload attachment</v-btn
        >
      </div>
      <div class="d-flex justify-content-end">
        <v-btn v-show="text !== ''" class="mr-2" outlined @click="cancelComment"
          >Cancel</v-btn
        >
        <v-btn
          color="green"
          class="px-4 white--text"
          :disabled="text === ''"
          :loading="actionLoading"
          data-ga="issue_detail_comment_created"
          @click="handleCommentClick"
          data-cy="submit-comment"
          >Comment</v-btn
        >
      </div>
    </div>
    <div
      class="d-flex flex-wrap attachment-list mt-2"
      v-if="attachments.length > 0"
    >
      <div
        v-for="(attachment, index) in attachments"
        :key="index"
        class="d-flex align-center pl-2 text-no-wrap"
      >
        {{ attachment.name }}
        <v-icon @click="removeAttachment(attachment)">mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { deepCopyFunction } from "@/helpers/functions";
//import UploadDialog from "@/components/UploadDialog";
/**
 * Displays a markdown editor for users to create comments on issues
 */
export default {
  name: "NewCommentEditor",
  // components: {
  //   UploadDialog,
  // },
  emits: ["postComment"],
  props: {
    attachments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      content: "", // Doesn't seem to be used anywhere
      /**
       * Initial values for the attributes of the markdown editor
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      /**
       * Holds the content of the comment being created in the editor
       */
      text: "",
      //  showDialog: false,
      clonedAttachments: [],
    };
  },
  computed: {
    ...mapState({
      actionLoading: (state) => state.comments.loading.addComment,
    }),
  },
  methods: {
    ...mapActions({
      addComment: "comments/addComment",
    }),
    /**
     * Triggers action to add comment to DB and comment list of the turbine issue
     */
    async handleCommentClick() {
      if (this.text) {
        await this.addComment({
          issueId: this.$route.params.issueId,
          comment: this.text,
          attachments: this.clonedAttachments,
        });
        /**
         * Emits the `postComment` event to `EditWidget`
         */
        this.$emit("postComment");
        this.text = "";
        this.clonedAttachments = [];
        this.$emit("emptyAttachments");
      }
    },
    cancelComment() {
      this.text = "";
      this.clonedAttachments = [];
      this.$emit("emptyAttachments");
    },
    toggleUploadDialog(showDialog) {
      this.$emit("openUploadDialog", showDialog);
    },
    removeAttachment(attachment) {
      this.clonedAttachments = this.clonedAttachments.filter(
        (item) => item !== attachment,
      );
      this.$emit("removeAttachment", attachment);
    },
  },
  watch: {
    attachments: {
      handler(data) {
        if (data.length > 0) {
          this.clonedAttachments = deepCopyFunction(data);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.editor {
  display: flex;
  flex-direction: column;
  background-color: var(--v-black10-base);
  border: 2px solid var(--v-border-base);
  border-radius: 6px;

  &__content {
    padding: 1.25rem 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.preview {
    background-color: transparent;
    border: 0;
  }
}

::v-deep {
  .editor {
    .v-md-editor__toolbar {
      border-color: var(--v-border-base);
    }
  }
}

.preview-editor {
  padding: 1.25rem 1rem;
  background-color: transparent;
}

.attachment-list {
  color: var(--v-text-base);
  height: 4rem;
  overflow-y: auto;
  // Styles for Firefox
  scrollbar-color: var(--v-border-base) var(--v-background-base);
  scrollbar-width: thin;
}

// Styles for Chrome, Edge, Safari,
.attachment-list::-webkit-scrollbar {
  width: 0.35rem;
}
.attachment-list::-webkit-scrollbar-track {
  background: var(--v-background-base);
}
.attachment-list::-webkit-scrollbar-thumb {
  background-color: var(--v-border-base);
  border-radius: 8px;
}
</style>

<style>
.v-md-textarea-editor pre,
.v-md-textarea-editor textarea {
  word-break: normal;
}
</style>
