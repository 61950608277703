<template>
  <div>
    <DataLoading v-if="loading.fetchIssueDetailData" />
    <div class="issue-page-outer__grid" v-else>
      <div class="outer-grid-row-1">
        <div class="issue-detail__header d-flex align-center flex-wrap">
          <h3 class="d-flex align-center">
            <span
              class="main-title"
              v-if="issueData && 'definition' in issueData"
            >
              {{ issueData.definition.name }}{{ valueWithUnits }}
            </span>
          </h3>
          <div
            v-if="issueData && 'definition' in issueData"
            class="issue-detail__header__help d-flex align-center justify-space-between"
            @click="handleHelpClick"
          >
            <v-icon>{{ informationIcon }}</v-icon>
          </div>
        </div>
      </div>
      <div class="outer-grid-col-1">
        <EvidenceOverview :plotlyHeight="360"></EvidenceOverview>
      </div>
      <div class="outer-grid-col-2">
        <AtAGlance
          page="issueDetail"
          :lossesGainsLoading="lossesGainsLoading"
        />
      </div>
      <div class="outer-grid-row-3 mt-1">
        <div class="new-tab">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            :class="{ tablinks: true, active: activeTab === tab.id }"
            @click="openTab(tab.id, 'click_event')"
          >
            {{ tab.name }}
          </button>
        </div>
        <div class="tabbed-section mb-4">
          <div id="Recommendations" class="tabcontent" v-show="activeTab === 1">
            <RecommendationsTab
              :latestStatus="latestStatus"
              :userHasInternalWriteAccess="userHasInternalWriteAccess"
              :userHasInternalAccess="userHasInternalAccess"
              :userHasWriteAccess="userHasWriteAccess"
              :recommendationSelection="recommendationSelection"
              :issueId="issueIdParam"
              :loadingTimeline="loadingTimeline"
              :lossesGainsLoading="lossesGainsLoading"
              :commentsTimelineItems="commentsTimelineItems"
              @postComment="refreshComments"
              @updateStatus="updateStatusHistory"
              @updateAtAGlance="updateAtAGlance"
            />
          </div>
          <div id="Explore" class="tabcontent pa-4" v-if="activeTab === 2">
            <div v-if="!isPowerCurveExplorerOpen">
              <NewAnalyticsView
                ref="analyticsView"
                :turbineData="turbinesData"
                :siteId="siteId"
                :issueCreatedTs="issueCreatedTs"
                :issueStartDate="issueStartDate"
                :issueEndDate="issueEndDate"
                :pageTitle="'Issue detail'"
                @setStaticViewRequest="setAnalyticsViewRequest"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
            <div v-if="isPowerCurveExplorerOpen">
              <NewPowerCurveExplorer
                :targetTurbineOptions="targetTurbineOptions"
                :loadingTurbines="loading.getTurbinesBySiteId"
                :siteId="siteId"
                :issueCreatedTs="issueCreatedTs"
                :issueStartDate="issueStartDate"
                :issueEndDate="issueEndDate"
                :pageTitle="'Issue detail'"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
          </div>
          <div id="Description" class="tabcontent" v-if="activeTab === 3">
            <div class="details-widget-container">
              <DataLoading v-if="loading.fetchIssueDetailData" />
              <div v-else>
                <div class="issue-description-container">
                  <div class="details-title">Details</div>
                  <div class="issue-description">
                    <vue-markdown
                      :breaks="false"
                      :anchor-attributes="anchorAttrs"
                      >{{ issueDescription }}</vue-markdown
                    >
                  </div>
                </div>
                <DetailsWidget
                  :editIssueDetails="editIssueDetails"
                  :issueDetailsTitle="issueDetailsTitle"
                  :userHasInternalWriteAccess="userHasInternalWriteAccess"
                  :editTurbineIssueDetails="editTurbineIssueDetails"
                  :editDetailsLoading="editDetailsLoading"
                  :issueDataDetails="issueDataDetails"
                  :sendIssueDetailsEdit="sendIssueDetailsEdit"
                  :cancelEditIssueDetails="cancelEditIssueDetails"
                  :supersededIssues="supersededIssues"
                />
              </div>
            </div>
          </div>
          <div id="LossModel" class="tabcontent" v-if="activeTab === 4">
            <NewLossModel :issueId="issueIdParam" ref="newLossModel" />
          </div>
          <div id="NextIssue" class="tabcontent" v-show="activeTab === 5">
            <NextIssueTab
              :issues="nextIssuesSorted"
              :siteId="siteId"
              :loading="loading.getPaginatedIssuesForIssuesTable"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* TODO: Change to this when ready to add elements and change name to IssueDetail of file and component name*/
import { mapState, mapActions } from "vuex";
import axios from "@/helpers/axiosAPI";
import { informationIcon, popupDelay } from "@/helpers/variables";
import { roundToString } from "@/helpers/functions";
import AtAGlance from "@/components/SummaryCards/AtAGlance";
import EvidenceOverview from "@/components/NewIssueDetailComponents/EvidenceOverview";
import RecommendationsTab from "@/components/NewIssueDetailComponents/RecommendationsTab";
import DetailsWidget from "@/components/DetailsWidget";
import NewLossModel from "@/components/NewIssueDetailComponents/NewLossModel";
import NewAnalyticsView from "@/components/NewIssueDetailComponents/NewAnalyticsView";
import NewPowerCurveExplorer from "@/components/NewIssueDetailComponents/NewPowerCurveExplorer";
import NextIssueTab from "@/components/NewIssueDetailComponents/NextIssueTab";
import DataLoading from "@/components/DataLoading";
import mixpanel from "mixpanel-browser";
import VueMarkdown from "vue-markdown-v2";

export default {
  name: "NewIssueDetail",
  components: {
    AtAGlance,
    EvidenceOverview,
    RecommendationsTab,
    DetailsWidget,
    NewLossModel,
    NewAnalyticsView,
    NewPowerCurveExplorer,
    NextIssueTab,
    DataLoading,
    VueMarkdown,
  },
  data() {
    return {
      isOpen: false,
      selectedSubscription: null,
      editIssueDetails: false,
      issueDetailsTitle: "Additional details",
      title: null,
      selectedSupersededIssue: {},
      informationIcon,
      popupDelay,
      lossesGainsLoading: false,
      tabs: [
        {
          id: 1,
          name: "RECOMMENDATIONS",
        },
        {
          id: 2,
          name: "EXPLORE",
        },
        {
          id: 3,
          name: "DESCRIPTION",
        },
        {
          id: 4,
          name: "LOSS MODEL",
        },
        {
          id: 5,
          name: "NEXT ISSUE",
        },
      ],
      activeTab: 1,
      commentsTimelineItems: [],
      loadingTimeline: false,
      isPowerCurveExplorerOpen: false,
      analyticsViewSelection: "yaw_misalignment",
      staticAnalyticsViewRequest: {},
      issueCreatedTs: null,
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.issueDetail.loading,
      apiError: (state) => state.issueDetail.error,
      issueData: (state) => state.issueDetail.issueData,
      issueStatuses: (state) => state.issueDetail.issueStatuses,
      statusHistory: (state) => state.issueDetail.statusHistory,
      isNotificationNew: (state) => state.notifications.isNotificationNew,
      latestStatus: (state) => state.issueDetail.latestStatus,
      recommendationSelection: (state) =>
        state.issueDetail.recommendationSelection,
      editDetailsLoading: (state) =>
        state.issueDetail.loading.editTurbineIssueDetails,
      turbinesData: (state) => state.site.turbinesData,
      issuesBySiteId: (state) => state.site.issuesForNewIssueDetail,
    }),
    userHasWriteAccess() {
      return this.issueData?.user_has_write_access;
    },
    userHasInternalAccess() {
      return this.issueData?.user_has_internal_access;
    },
    userHasInternalWriteAccess() {
      return this.userHasWriteAccess && this.userHasInternalAccess;
    },
    issueStatus() {
      return this.issueStatuses.filter(
        (issue) => issue.turbine_issue_id === this.issueIdParam,
      );
    },
    siteName() {
      if (this.issueData?.turbine) {
        return this.issueData.turbine.farm.name.toUpperCase();
      }
      return "";
    },
    siteId() {
      if (this.issueData?.turbine) {
        return this.issueData.turbine.farm_id;
      }
      return null;
    },
    issueDataId() {
      if (this.issueData?.id) {
        return this.issueData.id;
      }
      return "";
    },
    errorWatcher() {
      if (this.apiError) {
        return this.apiError;
      }

      if (Object.keys(this.issueData || {}).length === 0) {
        return "There is no data";
      }

      return "";
    },
    valueWithUnits() {
      let value = null;
      if (this.issueData.value) {
        value = ` (${this.issueData.value.toFixed(1)}`;
        if (this.issueData.definition.units) {
          if (this.issueData.definition.units === "degrees") {
            value = ` (${roundToString(this.issueData.value, 0)}`;
          }
          value += ` ${this.issueData.definition.units}`;
        }
        value += ")";
      }
      return value;
    },
    issueDataDetails() {
      return this.issueData?.details;
    },
    issueDescription() {
      return this.issueData?.definition.description;
    },
    supersededIssues() {
      if (this.issueData?.superseded_turbine_issues.length > 0) {
        return this.issueData.superseded_turbine_issues;
      } else {
        return [];
      }
    },
    targetTurbineOptions() {
      if (this.turbinesData.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    pageLoading() {
      if (this.loading.fetchIssueDetailData) {
        return true;
      } else {
        return false;
      }
    },
    issueIdParam() {
      return parseInt(this.$route.params.issueId);
    },
    issueStartDate() {
      if (this.statusHistory.length > 0) {
        return this.statusHistory[0].ts;
      }
      return null;
    },
    issueEndDate() {
      if (this.statusHistory.length > 0) {
        if (
          this.statusHistory[this.statusHistory.length - 1]
            .definition_name_external === "Fixed"
        ) {
          return this.statusHistory[this.statusHistory.length - 1].ts;
        } else {
          return null;
        }
      }
      return null;
    },
    nextIssues() {
      const issueId = parseInt(this.$route.params.issueId);
      let newOrInProgressIssues = [];

      if (this.issuesBySiteId.length > 0) {
        for (const issue of this.issuesBySiteId) {
          if (
            (issue.current_status_def_name_external === "New" ||
              issue.current_status_def_name_external === "In progress") &&
            issue.id !== issueId
          ) {
            newOrInProgressIssues.push(issue);
          }
        }
        if (newOrInProgressIssues.length > 0) {
          const mappedIssues = newOrInProgressIssues.map((issue) => {
            return {
              id: issue.id,
              name: issue.name,
              turbine: issue.turbine_name,
              aep_loss_mwhpyr: issue.aep_loss_mwhpyr,
              aep_loss_upper_usdpyr: issue.aep_loss_upper_usdpyr,
            };
          });
          return mappedIssues;
        }
      }
      return [];
    },
    nextIssuesSorted() {
      if (this.nextIssues.length > 0) {
        return this.sortIssues();
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      fetchIssueDetailData: "issueDetail/fetchIssueDetailData",
      getIssueStatusData: "issueDetail/getIssueStatusData",
      getLossesGains: "issueDetail/getLossesGains",
      getConfirmedIssues: "issues/getIssues",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
      getComments: "comments/getComments",
      updateIssueDetails: "issueDetail/editTurbineIssueDetails",
      editIssueEvidence: "issueDetail/editIssueEvidence",
      getCurrentEvidence: "evidence/getCurrentEvidence",
      setStaticCombinedEvidence: "evidence/setStaticCombinedEvidence",
      clearStaticCombinedEvidence: "evidence/clearStaticCombinedEvidence",
      getTurbinesBySiteId: "site/getTurbinesBySiteId",
      handleAxiosError: "error/raiseError",
      getIssuesBySiteId: "site/getPaginatedIssuesForIssuesTable",
    }),
    handleHelpClick() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        ...this.issueData.definition,
      });
    },
    updateStatusHistory() {
      const issueId = this.issueIdParam;
      this.getIssueStatusData({ issueId });
    },
    async refreshComments() {
      this.loadingTimeline = true;
      const issueId = this.issueIdParam;
      await this.getCommentsTimeline(issueId);
      this.loadingTimeline = false;
    },
    editTurbineIssueDetails() {
      this.editIssueDetails = true;
      this.issueDetailsTitle = "Editing details";
    },
    sendIssueDetailsEdit(detailsText) {
      if (this.issueData.id && detailsText) {
        let editObj = {
          id: +this.issueData.id,
          details: detailsText,
        };

        this.updateIssueDetails(editObj);
        this.editIssueDetails = false;
        this.issueDetailsTitle = "Details";
      }
    },
    cancelEditIssueDetails() {
      this.openedPanel = null;
      this.isOpen = false;
      this.editIssueDetails = false;
      this.issueDetailsTitle = "Details";
    },
    async handleStatusSelected(statusObj) {
      const turbineId = this.issueData?.turbine_id;
      if (
        statusObj.definition_name_external === "Superseded" ||
        statusObj.name_external === "Superseded"
      ) {
        await this.getConfirmedIssues({ turbineId });
      }
    },
    // Tab functions and navigation
    openTab(tabId, method) {
      // Instantiate the current url object for this page
      const url = new URL(window.location.href);
      this.activeTab = tabId;
      let tabName = "";
      // Set url hash for active tab
      for (const tab of this.tabs) {
        if (tab.id === tabId) {
          tabName = tab.name.toLowerCase();
          if (tabName.includes(" ")) {
            tabName = tabName.replace(" ", "_");
          }
        }
      }
      if (method !== "method") {
        mixpanel.track("new_issue_detail_tab_click", {
          tab_name: tabName,
        });
      }
      // Set the url hash to the active tab name
      url.hash = tabName;
      if (this.activeTab === 4) {
        this.goToLossModelTab();
      }
      if (this.activeTab === 2) {
        this.goToExploreTab();
      }
      // Replace the current url object with the new edited url object(we changed the hash of the url)
      history.replaceState(null, null, url);
    },
    isActiveTab(tabId) {
      return this.activeTab === tabId;
    },
    goToLossModelTab() {
      this.$nextTick(() => {
        this.$refs.newLossModel.handleClick();
      });
    },
    updateAtAGlance() {
      this.refreshComments();
      this.updateStatusHistory();
    },
    goToExploreTab() {
      const siteId = this.issueData.turbine.farm_id;
      if (
        siteId &&
        (siteId !== this.turbinesData[0]?.site_id ||
          this.turbinesData?.length === 0)
      ) {
        this.getTurbinesBySiteId(siteId);
        return;
      }
    },
    setAnalyticsViewRequest(request) {
      // Request details for setting url params on navigation to site analytics tab
      this.staticAnalyticsViewRequest.site_id = request.site_id;
      this.staticAnalyticsViewRequest.start_date = request.start_date;
      this.staticAnalyticsViewRequest.end_date = request.end_date;
      this.staticAnalyticsViewRequest.turbine_ids = request.turbine_ids;
      this.staticAnalyticsViewRequest.view = request.view;
    },
    togglePowerCurveExplorer(isOpen) {
      this.isPowerCurveExplorerOpen = isOpen;
    },
    updateAnalyticsViewSelected(view) {
      this.analyticsViewSelection = view;
    },
    // This will either call the open tab method with the url hash for the tab name
    // Or set the hash to the default tab
    setTabByUrl() {
      const url = new URL(window.location.href);
      if (url.hash) {
        let tabId = null;
        for (const tab of this.tabs) {
          let tabName = tab.name.toLowerCase();
          const hashName = url.hash.substring(1);
          tabName = tabName.replace(" ", "_");
          if (hashName === tabName) {
            tabId = tab.id;
            break;
          }
        }
        this.openTab(tabId, "method");
      } else {
        // Setting the default tab to recommendations
        url.hash = "recommendations";
        history.replaceState(null, null, url);
      }
    },
    sortIssues() {
      const sortedIssues = this.nextIssues
        .sort((a, b) => {
          const lossComparison =
            b.aep_loss_upper_usdpyr - a.aep_loss_upper_usdpyr;
          if (lossComparison === 0) {
            const dateA = new Date(a.created_ts);
            const dateB = new Date(b.created_ts);
            return dateA - dateB;
          }
          return lossComparison;
        })
        .slice(0, 4);

      return sortedIssues;
    },
    // API calls
    async getCommentsTimeline(issueId) {
      // Set a unique id for each request and save in a data variable
      try {
        const res = await axios.get(
          `turbine-issues/${issueId}/recommendations-timeline-events`,
        );

        if (res.status === 200) {
          this.commentsTimelineItems = res.data;
        } else {
          const error = {
            name: "getCommentsTimeline",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.powerCurve10mData = {};
        const err = {
          name: "getCommentsTimeline",
          error,
        };
        this.handleAxiosError(err);
      }
    },
  },
  async created() {
    const issueId = this.issueIdParam;
    this.loadingTimeline = true;
    this.lossesGainsLoading = true;
    if (
      this.issueData?.id != issueId ||
      !this.issueData ||
      Object.keys(this.issueData).length === 0
    ) {
      this.fetchIssueDetailData({ issueId });
      await Promise.all([
        this.getIssueStatusData({ issueId, isRecommendationsTab: true }),
        this.getLossesGains({ issueId }),
      ]);
    }
    if (this.commentsTimelineItems.length === 0) {
      await this.getCommentsTimeline(issueId);
      this.loadingTimeline = false;
    }
    this.lossesGainsLoading = false;
  },
  watch: {
    siteId: {
      immediate: true,
      handler(data) {
        if (
          data &&
          (this.issuesBySiteId.length === 0 ||
            (this.issuesBySiteId.length > 0 &&
              this.issuesBySiteId[0].farm_id !== data))
        ) {
          this.getIssuesBySiteId({
            siteId: this.siteId,
            component: "new_issue_detail",
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
/* CSS for new issue detail page layout */
.issue-page-outer__grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 50px auto 1fr;
  grid-row-gap: 0.25rem;
  grid-column-gap: 1rem;
  height: 100%;
}

.outer-grid-row-1 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.outer-grid-col-1 {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
}

.outer-grid-col-2 {
  min-height: 350px;
}

.outer-grid-row-3 {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  padding-bottom: 0.5rem;
}

.tabbed-section {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0;
  min-height: 150px;
}

.details-widget-container .v-card.v-sheet {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.issue-description-container {
  padding: 1rem 1rem 0 1rem;

  .details-title {
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: var(--v-text-base);
    margin-bottom: 0.5rem;
  }
  .issue-description {
    padding-bottom: 0.25rem;
    font-family: "Inter", sans-serif;
    color: var(--v-text-base);
    border-bottom: 1px solid var(--v-border-base);
  }
}

.issues-table-link {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  button a {
    border: 1.75px solid var(--v-inverse-base);
    border-radius: 3rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: transparent;
    color: var(--v-text-base);
    cursor: pointer;
    text-decoration: none;
  }
  button a:hover {
    background-color: var(--v-converterBorders-base);
    text-decoration: none;
  }
}

@media (max-width: 1264px) {
  .issue-page-outer__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 300px auto 1fr;
    row-gap: 1rem;
  }

  .outer-grid-row-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .outer-grid-col-1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    min-height: 400px;
  }

  .outer-grid-col-2 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-top: 0.5rem;
    min-height: 300px;
  }

  .outer-grid-row-3 {
    min-height: 100px;
    order: 4;
    padding-bottom: 0.5rem;
  }
}
/* End new issue detail css */

.issue-detail {
  &__subtitle {
    font:
      0.71rem "Museo Sans Rounded",
      sans-serif;
    color: var(--v-text-base);
    font-weight: 600;
  }

  &__header {
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 16px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.v-dialog > .v-card > .v-card__title .modal-label {
  font-size: 1rem;
}

::v-deep {
  .superseded-issues {
    .v-list {
      min-height: 10px;
    }
    .v-list-item {
      min-height: 5px;
      height: 1.5rem;
      padding-bottom: 0;
    }
    .v-list-item:hover {
      background-color: transparent;
    }
    .v-list-item p {
      color: var(--v-black4-base);
    }
    .v-list-item p:hover {
      background-color: transparent;
      cursor: pointer;
      color: rgba(50, 93, 171);
      text-decoration: underline;
    }
    .v-list-item__content {
      padding: 0;
    }
  }
  .superseded-issues-title {
    color: var(--v-black4-base);
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 1150px) {
  .third-card-no-padding {
    padding-left: 0;
  }
}
</style>
