<template>
  <div class="next-issues-tab-container">
    <div
      class="d-flex justify-center align-center next-issues-tab-container__spinner"
      v-if="loading"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <TableHeaderFilter
      :headers="tableHeaders"
      :selectedHeaders="selectedHeaders"
      menuActivator="#next-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="issues"
      :loading="loading"
      :hasFooter="false"
      tab-selected="next issue"
      menuActivator="next-col-filter"
    >
      <template v-slot:name="{ item }">
        <router-link
          :to="{
            name: 'NewIssueDetail',
            params: {
              issueId: item.id,
            },
          }"
          class="link-cell"
        >
          <div class="d-flex justify-space-between">
            <div class="link-text">{{ item.name }}</div>
            <div class="mr-1 pointer">
              <v-icon size="1.25rem">mdi-open-in-new</v-icon>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:aep_loss="{ item }">
        <span>{{
          item.aep_loss_mwhpyr ? roundToString(item.aep_loss_mwhpyr, -1) : 0
        }}</span
        ><span class="ml-1 mr-1">MWh/yr</span><span class="mr-1 ml-1">/</span
        ><span class="ml-1"
          >${{
            item.aep_loss_upper_usdpyr
              ? roundToString(item.aep_loss_upper_usdpyr, -1)
              : 0
          }}/yr</span
        >
      </template>
    </DynamicTable>
    <div class="issues-table-link mr-2">
      <button>
        <router-link
          :to="{
            name: 'NewSiteDashboard',
            params: {
              siteId: siteId,
            },
            hash: '#issues',
          }"
        >
          ALL SITE ISSUES
        </router-link>
      </button>
    </div>
  </div>
</template>

<script>
import DynamicTable from "@/components/DynamicTable.vue";
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import { roundToString } from "@/helpers/functions";

export default {
  name: "NextIssueTab",
  components: {
    DynamicTable,
    TableHeaderFilter,
  },
  props: {
    issues: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          id: 1,
          text: "NAME",
          value: "name",
        },
        {
          id: 2,
          text: "TURBINE",
          value: "turbine",
        },
        {
          id: 3,
          text: "AEP LOSS",
          value: "aep_loss",
        },
      ],
      selectedColumns: [],
      roundToString,
    };
  },
  computed: {
    selectedHeaders() {
      let result = this.tableHeaders;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (this.selectedColumns.length === this.tableHeaders.length) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.tableHeaders;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.tableHeaders;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        if (this.selectedColumns.includes(header)) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          this.selectedColumns.push(header);
        }
      }
    },
  },
  mounted() {
    this.selectedColumns = this.tableHeaders;
  },
};
</script>

<style lang="scss" scoped>
.next-issues-tab-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.issues-table-link {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  button a {
    border: 1.75px solid var(--v-inverse-base);
    border-radius: 3rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: transparent;
    color: var(--v-text-base);
    cursor: pointer;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-size: 0.75rem;
  }
  button a:hover {
    background-color: var(--v-converterBorders-base);
    text-decoration: none;
  }
}
</style>
