import { render, staticRenderFns } from "./RecommendationsTab.vue?vue&type=template&id=47a3ec44&scoped=true&"
import script from "./RecommendationsTab.vue?vue&type=script&lang=js&"
export * from "./RecommendationsTab.vue?vue&type=script&lang=js&"
import style0 from "./RecommendationsTab.vue?vue&type=style&index=0&id=47a3ec44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a3ec44",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VIcon,VProgressCircular,VTextField})
