<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div
          class="mr-2"
          :class="{
            'editable-details-title':
              issueDetailsTitle === 'Additional details',
            'card-title--black3': issueDetailsTitle === 'Details',
          }"
        >
          {{ issueDetailsTitle }}
        </div>
        <div
          class="mb-2 pointer"
          v-if="userHasInternalWriteAccess && !editIssueDetails"
        >
          <div @click.stop="editTurbineIssueDetails">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="19.5px"
                  class="mt-1"
                  color="black3 pointer"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Edit details</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-center justify-center mb-2"
        v-if="editDetailsLoading"
      >
        <v-progress-circular
          :size="36"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <EditIssueDetailsWidget
        :issueDetails="issueDataDetails"
        @sendIssueDetailsEdit="sendIssueDetailsEdit"
        @cancelEdit="cancelEditIssueDetails"
        v-else-if="editIssueDetails"
      />
      <div class="detail-markdown" v-else>
        <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">{{
          issueDataDetails
        }}</vue-markdown>
      </div>
      <div
        v-if="supersededIssues.length > 0 && !editIssueDetails"
        class="superseded-issues"
      >
        <p class="superseded-issues-title">
          This issue supersedes the following issues:
        </p>
        <v-list class="pt-0">
          <v-list-item v-for="issue in supersededIssues" :key="issue.id">
            <v-list-item-content>
              <div class="d-flex align-center superseded-issues-container">
                <span><v-icon size="2rem">mdi-circle-small</v-icon></span>
                <router-link
                  :to="{
                    name: 'IssueDetail',
                    params: {
                      orgId: issue.org_id,
                      siteId: issue.farm_id,
                      turbineId: issue.turbine_id,
                      issueId: issue.id,
                    },
                  }"
                >
                  {{ issue.definition.name }}
                </router-link>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueMarkdown from "vue-markdown-v2";
import EditIssueDetailsWidget from "@/components/EditIssueDetailsWidget";
export default {
  name: "DetailsWidget",
  components: {
    VueMarkdown,
    EditIssueDetailsWidget,
  },
  props: {
    editIssueDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    issueDetailsTitle: {
      type: String,
      required: false,
      default: "",
    },
    userHasInternalWriteAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    editTurbineIssueDetails: {
      type: Function,
      required: false,
      default: () => {},
    },
    editDetailsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    issueDataDetails: {
      type: String,
      required: false,
      default: "",
    },
    sendIssueDetailsEdit: {
      type: Function,
      required: false,
      default: () => {},
    },
    cancelEditIssueDetails: {
      type: Function,
      required: false,
      default: () => {},
    },
    supersededIssues: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
    };
  },
  computed: {
    currentPage() {
      return this.page;
    },
  },
};
</script>

<style lang="scss">
.detail-markdown .table {
  color: var(--v-text-base) !important;
}
</style>
<style lang="scss" scoped>
.editable-details-title {
  font-size: 1rem !important;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: var(--v-text-base);
}

.detail-markdown {
  color: var(--v-text-base);
}
</style>
