<template>
  <v-form v-model="detailsValid" ref="issueDetailsForm">
    <v-textarea
      v-model="issueDetailsText"
      :rules="issueDetailsRules"
      solo
      required
    ></v-textarea>
    <div class="d-flex justify-content-end">
      <v-btn outlined class="px-8" @click="cancelEdit">Cancel</v-btn>
      <v-btn
        :disabled="!detailsValid"
        color="green"
        class="ml-1 white--text px-3"
        @click="saveChanges"
      >
        Save changes</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EditIssueDetailsWidget",
  props: {
    issueDetails: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      issueDetailsText: this.issueDetails ? this.issueDetails : "",
      issueDetailsRules: [
        (v) =>
          !!v ||
          "Details must have some text entered to be applied to this issue.",
      ],
      detailsValid: false,
      saveDisabled: true,
    };
  },
  computed: {
    ...mapState({
      editDetailsLoading: (state) =>
        state.issueDetail.loading.editTurbineIssueDetails,
    }),
  },
  methods: {
    cancelEdit() {
      this.openAlert = false;
      this.$emit("cancelEdit");
    },
    saveChanges() {
      this.$emit("sendIssueDetailsEdit", this.issueDetailsText);
    },
  },
};
</script>

<style lang="scss" scoped>
.details-spinner {
  display: block;
  position: absolute;
  z-index: 1;
}
</style>
