<template>
  <div class="evidence-widget">
    <div class="evidence-spinner" v-if="evidenceLoading">
      <DataLoading />
    </div>
    <div v-else>
      <div class="edit-evidence-widget-container">
        <EditEvidenceWidget
          v-if="isEditEvidence"
          :isEdit="isEditEvidence"
          :evidenceItem="initialEvidences[evidenceIndex]"
          :evidenceIndex="evidenceIndex"
          @saveEditedEvidence="saveEditedEvidence"
          @cancelEdit="cancelEditEvidence"
        />
      </div>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <p class="mb-0 mr-1">Evidence</p>
          <p class="evidence-index mb-0">
            ({{ evidenceIndex + 1 }}/{{ initialEvidences.length }})
          </p>
        </div>
        <div class="d-flex">
          <div class="d-flex align-start switch-labels">
            <label class="mr-3 mb-1">Snapshot</label>
            <v-switch
              v-model="showCurrentEvidence"
              hide-details
              dense
              class="toggle ma-0 pa-0"
              :disabled="isCurrentSwitchDisabled"
              @change="handleChangeCurrentSwitch"
            ></v-switch>
            <label class="mr-2 mb-1">Current</label>
          </div>
          <div class="pointer mr-1">
            <v-tooltip top :open-delay="popupDelay" v-if="!isEditEvidence">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="1.25rem"
                  class="info-icon"
                  >{{ informationIcon }}</v-icon
                >
              </template>
              <span
                ><p class="mb-0">
                  Snapshot evidence is that which was created when the issue was
                  detected.
                </p>
                <p class="mb-0">
                  Current evidence reflects the new data added to the system
                  since then.
                </p></span
              >
            </v-tooltip>
          </div>
          <div v-if="!isEditEvidence">
            <v-tooltip bottom open-delay="100">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1 pointer"
                  data-ga="issue_detail_evidence_full_chart_true"
                  @click="seeFullChart()"
                  >mdi-arrow-expand-all</v-icon
                >
              </template>
              <span>See full chart</span>
            </v-tooltip>
          </div>
          <div v-if="userHasInternalWriteAccess && !isEditEvidence">
            <v-tooltip bottom open-delay="100">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="19.5px"
                  class="pointer"
                  @click.stop="editEvidence"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Edit evidence</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <!-- End of 1st row -->
      <div>
        <span class="evidence-instructions">
          <i class="evidence-instructions"
            >Click and drag to zoom in to a selection on the plot. Double click
            to reset.</i
          >
        </span>
      </div>
      <!-- End 2nd row -->
      <div>
        <div v-if="currentData.uri" class="evidence-grid">
          <div class="carousel-button">
            <button type="button" @click="previous">
              <v-icon :color="chevronBackgroundColor" size="24"
                >mdi-chevron-left</v-icon
              >
            </button>
          </div>
          <div>
            <div class="image-overlay" v-if="isValidImage() && isEdit"></div>
            <div class="plotly-overlay" v-if="isValidPlotly() && isEdit"></div>
            <img
              v-if="isValidImage()"
              :src="getUri()"
              alt="Evidence"
              height="300"
            />
            <Plotly
              id="plotly_diagram"
              v-if="isValidPlotly() && !evidenceLoading"
              :data="currentData.plotlyData.data"
              :layout="currentData.plotlyData.layout"
              :responsive="true"
              :display-mode-bar="true"
              :showTips="false"
            ></Plotly>
          </div>
          <div class="carousel-button">
            <button type="button" @click="next">
              <v-icon :color="chevronBackgroundColor" size="24"
                >mdi-chevron-right</v-icon
              >
            </button>
          </div>
        </div>
      </div>
      <!-- End of 3rd row -->
      <div>
        <div class="evidence-description pt-2">
          <div>
            <span class="evidence-overview__content__title">
              {{ currentData.title }} (Turbine {{ currentTurbine }})
              <span v-if="currentData.internal" class="internal-badge"
                >INTERNAL</span
              >
            </span>
          </div>
          <div>
            <p>{{ currentData.description }}</p>
          </div>
        </div>
      </div>
      <!-- End of 4th row -->
      <!-- Main evidence full chart -->
      <v-dialog
        v-model="dialog"
        :disabled="initialEvidences.length === 0"
        max-width="1140px"
        width="100%"
        hide-overlay
      >
        <v-card>
          <div
            class="d-flex align-center justify-center pt-6"
            v-if="initialEvidences.length === 0"
          >
            <v-progress-circular
              :size="45"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-card-title
            style="border-bottom: 1px solid; border-color: #d8dbe0"
            class="d-flex justify-space-between"
            v-else
          >
            <div class="d-flex align-center cmodal-title">
              <div>
                {{ currentData.title }}
              </div>
              <div v-if="isInternalEvidence" class="internal-badge">
                INTERNAL
              </div>
            </div>
            <div class="d-flex align-center">
              <v-tooltip top :open-delay="popupDelay">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex ml-4 mr-2 align-center"
                    v-bind="attrs"
                    v-on="on"
                    v-if="!isEditEvidence"
                  >
                    <span class="mr-2 label modal-label">Snapshot</span>
                    <v-switch
                      v-model="showCurrentEvidence"
                      dense
                      :disabled="isCurrentSwitchDisabled"
                      @change="handleChangeCurrentSwitch"
                      class="ma-0"
                      hide-details
                    ></v-switch>
                    <span class="label modal-label">Current</span>
                  </div>
                </template>
                <span
                  >Snapshot evidence is that which was created when the issue
                  was detected. Current evidence reflects the new data added to
                  the system since then.</span
                >
              </v-tooltip>
              <div class="pointer pb-1">
                <v-tooltip top :open-delay="popupDelay" v-if="!isEditEvidence">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="1.25rem"
                      class="info-icon"
                      >{{ informationIcon }}</v-icon
                    >
                  </template>
                  <span
                    >Snapshot evidence is that which was created when the issue
                    was detected. Current evidence reflects the new data added
                    to the system since then.</span
                  >
                </v-tooltip>
              </div>
              <v-btn icon @click="closeDialog">
                <v-icon data-ga="issue_detail_evidence_full_chart_false"
                  >mdi-close</v-icon
                >
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="mt-4">
            <EvidenceViewModal
              v-if="initialEvidences.length > 0"
              :evidence-index="evidenceIndex"
              :evidence="initialEvidences[evidenceIndex]"
              :evidences="initialEvidences"
              page="issue_detail"
              :showCurrentEvidence="
                initialEvidences[evidenceIndex].current
                  ? initialEvidences[evidenceIndex].current.show
                    ? true
                    : false
                  : false
              "
              @updateTitle="updateTitle"
              @clickPrevious="previous"
              @clickNext="next"
          /></v-card-text>
        </v-card>
      </v-dialog>
      <div
        @click="closeDialog"
        class="backdrop"
        data-ga="issue_detail_evidence_full_chart_false"
        v-if="dialog"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Plotly from "@/components/Plotly";
import EditEvidenceWidget from "@/components/EditEvidenceWidget";
import EvidenceViewModal from "@/components/EvidenceViewModal";
import { informationIcon, popupDelay } from "@/helpers/variables";
import { getPlotlyData } from "@/services/issueService";
import DataLoading from "@/components/DataLoading";

export default {
  name: "EvidenceOverview",
  components: {
    Plotly,
    EditEvidenceWidget,
    EvidenceViewModal,
    DataLoading,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    plotlyHeight: {
      type: Number,
      default: null,
    },
    plotlyWidth: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showCurrentEvidence: false,
      evidenceIndex: 0,
      isDataLoaded: false,
      dialog: false,
      isEditEvidence: false,
      evidences: [],
      evidenceLoading: true,
      informationIcon,
      popupDelay,
    };
  },
  async mounted() {
    const issueId = parseInt(this.$route.params.issueId);
    if (
      this.issueData?.id != issueId ||
      !this.issueData ||
      Object.keys(this.issueData).length === 0
    ) {
      this.fetchIssueDetailData({ issueId });
      await Promise.all([this.getIssueStatusData({ issueId })]);
    }
    await this.setEvidence();
  },
  watch: {
    evidences: {
      async handler(data) {
        const turbineIssueId = parseInt(this.$route.params.issueId);
        if (data.length > 0) {
          const objToSend = {
            turbineIssueId,
            evidences: this.evidences,
          };
          await this.getCurrentEvidence(objToSend);
          this.evidenceLoading = false;
        }
      },
    },
    okToSetEvidence: {
      immediate: true,
      async handler(data) {
        if (data) {
          this.setEvidence();
        }
      },
    },
    evidenceIndex: {
      handler(data) {
        if (data >= 0) {
          if (this.initialEvidences[data]?.current?.show) {
            this.showCurrentEvidence = true;
          } else if (this.initialEvidences[data]?.snapshot?.show) {
            this.showCurrentEvidence = false;
          }
        }
      },
    },
  },
  computed: {
    ...mapState({
      combinedEvidences: (state) => state.evidence.combinedEvidences,
      staticCombinedEvidences: (state) =>
        state.evidence.staticCombinedEvidences,
      issueData: (state) => state.issueDetail.issueData,
    }),
    okToSetEvidence() {
      return this.issueData && Object.keys(this.issueData).length > 0;
    },
    currentData() {
      if (
        this.initialEvidences &&
        this.initialEvidences.length > this.evidenceIndex
      ) {
        const evidence = this.initialEvidences[this.evidenceIndex];
        let data = {};
        if ((evidence && evidence.current) || evidence.snapshot) {
          data =
            evidence.current && evidence.current.show
              ? evidence.current
              : evidence.snapshot;
        } else {
          data = evidence;
        }
        return this.adjustLayoutForPlotly(data);
      } else {
        return {};
      }
    },
    currentTurbine() {
      return this.issueData.turbine.shortname;
    },
    initialEvidences() {
      const turbineIssueId = +this.$route.params.issueId;
      if (
        this.staticCombinedEvidences.length > 0 &&
        this.staticCombinedEvidences.length === this.combinedEvidences.length &&
        this.staticCombinedEvidences[0].snapshot.turbine_issue_id ===
          turbineIssueId
      ) {
        return this.staticCombinedEvidences;
      } else if (this.combinedEvidences.length > 0) {
        return this.combinedEvidences;
      } else {
        return [];
      }
    },
    isInternalEvidence() {
      if (
        this.initialEvidences.length > 0 &&
        this.initialEvidences[this.evidenceIndex]
      ) {
        if (this.initialEvidences[this.evidenceIndex]?.current?.show) {
          return this.initialEvidences[this.evidenceIndex]?.current?.internal;
        } else {
          return this.initialEvidences[this.evidenceIndex]?.snapshot?.internal;
        }
      } else {
        return false;
      }
    },
    dataHasMultipleProperties() {
      if (this.currentData && "current" in this.currentData) {
        return true;
      }
      return false;
    },
    isCurrentSwitchDisabled() {
      if (
        this.initialEvidences?.length > 0 &&
        this.initialEvidences[this.evidenceIndex]?.current?.hasMatch &&
        this.initialEvidences[this.evidenceIndex]?.snapshot?.hasMatch
      ) {
        return false;
      } else {
        return true;
      }
    },
    userHasWriteAccess() {
      return this.issueData?.user_has_write_access;
    },
    userHasInternalAccess() {
      return this.issueData?.user_has_internal_access;
    },
    userHasInternalWriteAccess() {
      return this.userHasWriteAccess && this.userHasInternalAccess;
    },
    chevronBackgroundColor() {
      if (this.$vuetify.theme.isDark) {
        return "#c9d1d9";
      } else {
        return "#242426";
      }
    },
  },
  methods: {
    ...mapActions({
      fetchIssueDetailData: "issueDetail/fetchIssueDetailData",
      getIssueStatusData: "issueDetail/getIssueStatusData",
      getEvidences: "evidence/getEvidences",
      getCurrentEvidence: "evidence/getCurrentEvidence",
      setStaticCombinedEvidence: "evidence/setStaticCombinedEvidence",
      clearStaticCombinedEvidence: "evidence/clearStaticCombinedEvidence",
    }),
    adjustLayoutForPlotly(data) {
      if (!data || !data.plotlyData || !data.plotlyData.layout) {
        this.isDataLoaded = false;
        return data;
      }
      let axisCount = 1;
      let hasMoreAxes;
      const gridColor = this.$vuetify.theme.isDark
        ? "rgba(255,255,255,0.1)"
        : "rgba(0,0,0,0.1)";
      const zeroLineColor = this.$vuetify.theme.isDark
        ? "rgba(255,255,255,0.1)"
        : "rgba(0,0,0,0.1)";
      const linecolor = this.$vuetify.theme.isDark
        ? "rgba(255,255,255,0.1)"
        : "rgba(0,0,0,0.1)";
      const tickfontColor = this.$vuetify.theme.isDark ? "#c9d1d9" : "";
      let layout = {
        ...data.plotlyData.layout,
        legend: {
          orientation: "h",
          y: -0.12,
          x: 0,
          traceorder: "normal",
          font: {
            size: 9,
            color: this.$vuetify.theme.isDark ? "#fff" : "#000",
          },
          bordercolor: "#FFFFFF",
          borderwidth: 0,
        },
        margin: {
          l: 0,
          r: 5,
          b: 0,
          t: 50,
          pad: 1,
        },
        autosize: true,
        paper_bgcolor: this.$vuetify.theme.isDark ? "#121212" : "#f7f7f8",
        plot_bgcolor: this.$vuetify.theme.isDark ? "#121212" : "transparent",
        font: {
          color: this.$vuetify.theme.isDark ? "#fff" : "",
          size: 9,
        },
        title: null,
        height: this.plotlyHeight || "450",
      };
      // Search layout for more than one pair of axes
      do {
        hasMoreAxes =
          `xaxis${axisCount + 1}` in data.plotlyData.layout &&
          `yaxis${axisCount + 1}` in data.plotlyData.layout;
        if (hasMoreAxes) {
          axisCount++;
        }
      } while (hasMoreAxes);
      // Use the axis count to adjust the layout for each axis
      for (let i = 1; i <= axisCount; i++) {
        const suffix = i === 1 ? "" : i;
        layout[`xaxis${suffix}`] = {
          ...data.plotlyData.layout[`xaxis${suffix}`],
          gridcolor: gridColor,
          zerolinecolor: zeroLineColor,
          linecolor: linecolor,
          tickfont: {
            color: tickfontColor,
          },
        };
        layout[`yaxis${suffix}`] = {
          ...data.plotlyData.layout[`yaxis${suffix}`],
          gridcolor: gridColor,
          zerolinecolor: zeroLineColor,
          linecolor: linecolor,
          tickfont: {
            color: tickfontColor,
          },
        };
      }
      this.isDataLoaded = true;
      return { ...data, plotlyData: { ...data.plotlyData, layout } };
    },
    setDataLoaded(data) {
      this.isDataLoaded = !!(data.plotlyData.data && data.plotlyData.layout);
    },
    setDataByUrl(mode) {
      const url = new URL(window.location.href);
      let evidenceParam = null;
      let evidenceZoomed = null;
      let evidenceView = null;
      // Check for initial load
      if (this.currentData.length > 0 && mode === "before_mount") {
        if (url.searchParams.get("evidence")) {
          evidenceParam = Number(url.searchParams.get("evidence"));
        }
        if (url.search.includes("evidence_zoomed")) {
          evidenceZoomed = Number(url.searchParams.get("evidence_zoomed"));
        }
        if (url.search.includes("evidence_view")) {
          evidenceView = url.searchParams.get("evidence_view");
        }

        if (evidenceParam) {
          url.search = "";
          url.searchParams.set("evidence", evidenceParam);
          this.evidenceIndex = evidenceParam - 1;
          if (evidenceView === "current") {
            this.showCurrentEvidence = true;
            url.searchParams.set("evidence_view", "current");
          } else {
            this.showCurrentEvidence = false;
            url.searchParams.set("evidence_view", "snapshot");
          }
          if (evidenceZoomed) {
            url.searchParams.set("evidence_zoomed", evidenceZoomed);
            this.seeFullChart();
          }
        } else {
          url.search = "";
          url.searchParams.set("evidence", 1);
          url.searchParams.set("evidence_view", "snapshot");
        }
      } else if (this.currentData.length > 0) {
        url.search = "";
        url.searchParams.set("evidence", this.evidenceIndex + 1);
        const type = this.initialEvidences[this.evidenceIndex].current?.show
          ? "current"
          : "snapshot";
        url.searchParams.set("evidence_view", type);
        url.searchParams.set("evidence_zoomed", this.dialog ? 1 : 0);
      } else {
        url.search = "";
      }

      history.replaceState(null, null, url);
    },
    isValidPlotly() {
      if (
        (this.currentData?.current &&
          this.currentData?.current?.type?.includes("plotly")) ||
        (!this.currentData?.current &&
          this.currentData?.snapshot &&
          this.currentData?.snapshot?.type?.includes("plotly")) ||
        this.currentData?.type?.includes("plotly")
      ) {
        return true;
      }
      return false;
    },
    isValidImage() {
      if (
        (this.currentData?.current &&
          (this.currentData?.current?.type === "image" ||
            this.currentData?.current?.file_type === "image")) ||
        (!this.currentData?.current &&
          this.currentData?.snapshot &&
          (this.currentData?.snapshot?.type === "image" ||
            this.currentData?.snapshot?.file_type === "image")) ||
        (("type" in this.currentData || "file_type" in this.currentData) &&
          (this.currentData?.type === "image" ||
            this.currentData?.file_type === "image"))
      ) {
        return true;
      }
      return false;
    },
    getUri() {
      const isValid = this.isValidImage();
      if (isValid) {
        if (this.dataHasMultipleProperties) {
          return this.currentData.current
            ? this.currentData.current.uri
            : this.currentData.snapshot.uri;
        } else {
          return this.currentData.uri;
        }
      }
    },
    navigateEvidence(direction) {
      const evidenceCount = this.initialEvidences.length;
      if (direction === "next") {
        this.evidenceIndex = (this.evidenceIndex + 1) % evidenceCount;
      } else if (direction === "previous") {
        this.evidenceIndex =
          (this.evidenceIndex - 1 + evidenceCount) % evidenceCount;
      }

      this.showCurrentEvidence =
        this.initialEvidences[this.evidenceIndex]?.current?.hasMatch ?? false;
      this.setDataByUrl();
    },
    previous() {
      this.navigateEvidence("previous");
    },
    next() {
      this.navigateEvidence("next");
    },
    handleChangeCurrentSwitch(event) {
      const url = new URL(window.location.href);
      let params = {
        current: "hide",
        snapshot: "show",
        index: this.evidenceIndex,
        evidenceArray: this.combinedEvidences,
      };
      if (event) {
        url.searchParams.set("evidence_view", "current");
        params.current = "show";
        params.snapshot = "hide";
      } else {
        url.searchParams.set("evidence_view", "snapshot");
      }
      this.setStaticCombinedEvidence(params);

      history.replaceState(null, null, url);
    },
    seeFullChart() {
      const url = new URL(window.location.href);
      const zoomed = +url.searchParams.get("evidence_zoomed");
      this.dialog = true;
      if (!zoomed) {
        url.searchParams.set("evidence_zoomed", 1);
        history.replaceState(null, null, url);
      }
    },
    closeDialog() {
      const url = new URL(window.location.href);
      url.searchParams.set("evidence_zoomed", 0);
      url.searchParams.set("evidence", this.evidenceIndex + 1);
      this.dialog = false;

      history.replaceState(null, null, url);
    },
    editEvidence() {
      if (this.evidenceIndex || this.evidenceIndex === 0) {
        this.isEditEvidence = !this.isEditEvidence;
      }
    },
    async saveEditedEvidence(evidenceObj) {
      if (this.initialEvidences.length > 0 && evidenceObj) {
        const issueId = this.issueData?.id;
        await this.editIssueEvidence(evidenceObj);
        await this.fetchIssueDetailData({ issueId });
        this.isEditEvidence = false;
      }
    },
    cancelEditEvidence() {
      this.isEditEvidence = false;
    },
    updateTitle(updateObj) {
      this.title = updateObj.title;
      this.evidenceIndex = updateObj.currentIndex;
    },
    updateIndex(value) {
      this.evidenceIndex = value;
    },
    async generateEvidenceData(data) {
      let plotlyData = null;
      if (data.file_type.includes("plotly")) {
        const res = await getPlotlyData(data.uri);
        if (res.status === "success") {
          plotlyData = res.data;
        }
      }
      return {
        id: data.id,
        name: data.name,
        title: data.title || "N/A",
        description: data.description || "N/A",
        uri: data.uri || "",
        type: data.file_type,
        internal: data.internal,
        plotlyData,
      };
    },
    async generateEvidenceList(evidencePromises) {
      try {
        this.evidences = await Promise.all(evidencePromises);
      } catch (error) {
        console.error(error);
        this.evidenceLoading = false;
      }
    },
    setEvidence() {
      // Get all legacy evidence figures/data for this issue
      if (
        this.issueData &&
        Object.keys(this.issueData).length > 0 &&
        this.issueData.evidence &&
        this.issueData.evidence.length > 0
      ) {
        const promises = [];
        for (let i = 0; i < this.issueData.evidence.length; i++) {
          promises.push(this.generateEvidenceData(this.issueData.evidence[i]));
        }
        this.generateEvidenceList(promises);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
.evidence-widget {
  padding: 0.5rem;
}

.evidence-spinner {
  margin-top: 9rem;
}
.evidence-overview-container {
  padding: 0;
  min-height: 400px;
}

.evidence-index {
  font-size: 9;
}

.evidence-instructions {
  font-size: 0.875rem;
}

.evidence-overview__content__title {
  font-size: 1rem;
  font-weight: 500;
}

.internal-badge {
  transform: 0.5;
}

.evidence-grid {
  display: grid;
  grid-template-columns: 45px auto 45px;
  gap: 0.25rem;
}

.carousel-button {
  margin: auto;
  button {
    background-color: var(--v-border-base);
    border-radius: 50%;
    padding: 0.25rem;
  }
}

.evidence-description {
  margin-left: 3rem;
}
</style>
