var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"next-issues-tab-container"},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center next-issues-tab-container__spinner"},[_c('v-progress-circular',{attrs:{"size":40,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('TableHeaderFilter',{attrs:{"headers":_vm.tableHeaders,"selectedHeaders":_vm.selectedHeaders,"menuActivator":"#next-col-filter"},on:{"changeHeaders":_vm.changeSelectedHeaders}}),_c('DynamicTable',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.issues,"loading":_vm.loading,"hasFooter":false,"tab-selected":"next issue","menuActivator":"next-col-filter"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link-cell",attrs:{"to":{
          name: 'NewIssueDetail',
          params: {
            issueId: item.id,
          },
        }}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"link-text"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"mr-1 pointer"},[_c('v-icon',{attrs:{"size":"1.25rem"}},[_vm._v("mdi-open-in-new")])],1)])])]}},{key:"aep_loss",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.aep_loss_mwhpyr ? _vm.roundToString(item.aep_loss_mwhpyr, -1) : 0))]),_c('span',{staticClass:"ml-1 mr-1"},[_vm._v("MWh/yr")]),_c('span',{staticClass:"mr-1 ml-1"},[_vm._v("/")]),_c('span',{staticClass:"ml-1"},[_vm._v("$"+_vm._s(item.aep_loss_upper_usdpyr ? _vm.roundToString(item.aep_loss_upper_usdpyr, -1) : 0)+"/yr")])]}}])}),_c('div',{staticClass:"issues-table-link mr-2"},[_c('button',[_c('router-link',{attrs:{"to":{
          name: 'NewSiteDashboard',
          params: {
            siteId: _vm.siteId,
          },
          hash: '#issues',
        }}},[_vm._v(" ALL SITE ISSUES ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }